import { useRef, useEffect, useLayoutEffect, useState } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import { vh, vw } from "../../helpers/globals";
import ourCommitmentsDiscoverTimeline from "../../helpers/ourCommitmentsDiscoverTimeline";
import ourCommitmentsFightingCo2Timeline from "../../helpers/ourCommitmentsFightingCo2Timeline";
import ourCommitmentsInOutTimeline from "../../helpers/ourCommitmentsInOutTimeline";
import ourCommitmentsInvestmentsTimeline from "../../helpers/ourCommitmentsInvestmentsTimeline";
import ourCommitmentsTheFutureTimeline from "../../helpers/ourCommitmentsTheFutureTimeline";

import OurCommitmentsProgressBar from "./OurCommitmentsProgressBar";
import OurCommitmentsPallini from "./OurCommitmentsPallini";
import OurCommitmentsElements from "./OurCommitmentsElements";
import OurCommitmentsNuvole from "./OurCommitmentsNuvole";
import OurCommitmentsMezzi from "./OurCommitmentsMezzi";
import OurCommitmentsFondoMappa from "./OurCommitmentsFondoMappa";

import "../../../css/pages/ourcommitments/our-commitments-mappa.scss";

export default function OurCommitmentsMappa({ mapStateProp, scrollCompleteHandlerProp }) {
  gsap.registerPlugin(ScrollTrigger);

  const scrollCompleteHandlerPropRef = useRef();
  scrollCompleteHandlerPropRef.current = scrollCompleteHandlerProp;

  const [fondoLoaded, setFondoLoaded] = useState(false);
  const fondoLoadedHandler = (fondoLoaded) => {
    setFondoLoaded(fondoLoaded);
  };

  // Elements state

  const [rottamiState, setRottamiState] = useState("pause");
  const [fonderia1State, setFonderia1State] = useState("pause");
  const [fonderia2State, setFonderia2State] = useState("pause");
  const [magazzinoState, setMagazzinoState] = useState("pause");
  const [pannelliFonderiaState, setPannelliFonderiaState] = useState("pause");
  const [fonderia3State, setFonderia3State] = useState("pause");
  const [rifiutiTrenoState, setRifiutiTrenoState] = useState("pause");
  const [treniState, setTreniState] = useState("pause");
  const [fabbrica1State, setFabbrica1State] = useState("pause");
  const [capannoneState, setCapannoneState] = useState("pause");
  const [fabbrica2State, setFabbrica2State] = useState("pause");
  const [cantiereState, setCantiereState] = useState("pause");

  const [pannelliFabbricaState, setPannelliFabbricaState] = useState("pause");
  const [villaggioRosaState, setVillaggioRosaState] = useState("pause");
  const [pannelliFonderiaDestraState, setPannelliFonderiaDestraState] = useState("pause");
  const [parcoEolicoState, setParcoEolicoState] = useState("pause");
  const [idrogenoState, setIdrogenoState] = useState("pause");
  const [fattoriaState, setFattoriaState] = useState("pause");

  const [coloreElements, setColoreElements] = useState("bianco");

  // Elements State Handlers

  const rottamiStateHandler = (rottamiState) => {
    setRottamiState(rottamiState);
  };
  const fonderia1StateHandler = (fonderia1State) => {
    setFonderia1State(fonderia1State);
  };
  const fonderia2StateHandler = (fonderia2State) => {
    setFonderia2State(fonderia2State);
  };
  const magazzinoStateHandler = (magazzinoState) => {
    setMagazzinoState(magazzinoState);
  };
  const pannelliFonderiaStateHandler = (pannelliFonderiaState) => {
    setPannelliFonderiaState(pannelliFonderiaState);
  };
  const fonderia3StateHandler = (fonderia3State) => {
    setFonderia3State(fonderia3State);
  };
  const rifiutiTrenoStateHandler = (rifiutiTrenoState) => {
    setRifiutiTrenoState(rifiutiTrenoState);
  };
  const treniStateHandler = (treniState) => {
    setTreniState(treniState);
  };
  const fabbrica1StateHandler = (fabbrica1State) => {
    setFabbrica1State(fabbrica1State);
  };
  const capannoneStateHandler = (capannoneState) => {
    setCapannoneState(capannoneState);
  };
  const fabbrica2StateHandler = (fabbrica2State) => {
    setFabbrica2State(fabbrica2State);
  };
  const cantiereStateHandler = (cantiereState) => {
    setCantiereState(cantiereState);
  };
  const pannelliFabbricaStateHandler = (pannelliFabbricaState) => {
    setPannelliFabbricaState(pannelliFabbricaState);
  };
  const villaggioRosaStateHandler = (villaggioRosaState) => {
    setVillaggioRosaState(villaggioRosaState);
  };
  const pannelliFonderiaDestraStateHandler = (pannelliFonderiaDestraState) => {
    setPannelliFonderiaDestraState(pannelliFonderiaDestraState);
  };
  const parcoEolicoStateHandler = (parcoEolicoState) => {
    setParcoEolicoState(parcoEolicoState);
  };
  const idrogenoStateHandler = (idrogenoState) => {
    setIdrogenoState(idrogenoState);
  };
  const fattoriaStateHandler = (fattoriaState) => {
    setFattoriaState(fattoriaState);
  };

  // Pallini States

  const [discoverPalliniState, setdiscoverPalliniState] = useState("initial");
  const [fightingCo2PalliniState, setFightingCo2PalliniState] = useState("initial");
  const [inOutPalliniState, setInOutPalliniState] = useState("initial");
  const [investmentsPalliniState, setInvestmentsPalliniState] = useState("initial");
  const [theFuturePalliniState, setTheFuturePalliniState] = useState("initial");

  // Pallini Handlers

  const discoverPalliniStateHandler = (discoverPalliniState) => {
    setdiscoverPalliniState(discoverPalliniState);
  };
  const fightingCo2PalliniStateHandler = (fightingCo2PalliniState) => {
    setFightingCo2PalliniState(fightingCo2PalliniState);
  };
  const inOutPalliniStateHandler = (inOutPalliniState) => {
    setInOutPalliniState(inOutPalliniState);
  };
  const investmentsPalliniStateHandler = (investmentsPalliniState) => {
    setInvestmentsPalliniState(investmentsPalliniState);
  };
  const theFuturePalliniStateHandler = (theFuturePalliniState) => {
    setTheFuturePalliniState(theFuturePalliniState);
  };

  // Progress State

  const [progress, setProgress] = useState(0);
  const progressHandler = (progress) => {
    setProgress(progress);
  };
  const progressHandlerRef = useRef();
  progressHandlerRef.current = progressHandler;

  const ourCommitments = useRef();
  const ctx = useRef();
  const pannelliFabbricaStateRef = useRef();
  const villaggioRosaStateRef = useRef();
  const pannelliFonderiaDestraStateRef = useRef();
  const parcoEolicoStateRef = useRef();
  const idrogenoStateRef = useRef();
  const fattoriaStateRef = useRef();

  const coloreElementsRef = useRef();

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      gsap.delayedCall(1.7, () => {
        setRottamiState("play");
      });
      gsap.delayedCall(1.9, () => {
        setFonderia1State("play");
      });
      gsap.delayedCall(2, () => {
        setFonderia2State("play");
      });
      gsap.delayedCall(2.3, () => {
        setMagazzinoState("play");
      });
      gsap.delayedCall(1.8, () => {
        setPannelliFonderiaState("play");
      });
      gsap.delayedCall(2.5, () => {
        setFonderia3State("play");
      });
      gsap.delayedCall(2.4, () => {
        setRifiutiTrenoState("play");
      });
      gsap.delayedCall(2.5, () => {
        setTreniState("play");
      });
      gsap.delayedCall(2.5, () => {
        setTreniState("play");
      });
      gsap.delayedCall(2.4, () => {
        setFabbrica1State("play");
      });
      gsap.delayedCall(2.6, () => {
        setCapannoneState("play");
      });
      gsap.delayedCall(2.8, () => {
        setFabbrica2State("play");
      });
      gsap.delayedCall(2.6, () => {
        setCantiereState("play");
      });
    }, ourCommitments);
    return () => ctx.current.revert();
  }, []);

  useEffect(() => {
    pannelliFabbricaStateRef.current = pannelliFabbricaState;
    villaggioRosaStateRef.current = villaggioRosaState;
    pannelliFonderiaDestraStateRef.current = pannelliFonderiaDestraState;
    parcoEolicoStateRef.current = parcoEolicoState;
    idrogenoStateRef.current = idrogenoState;
    fattoriaStateRef.current = fattoriaState;
    coloreElementsRef.current = coloreElements;
  }, [
    coloreElements,
    fattoriaState,
    idrogenoState,
    pannelliFabbricaState,
    pannelliFonderiaDestraState,
    parcoEolicoState,
    villaggioRosaState,
  ]);

  useEffect(() => {
    if (mapStateProp === "discover") {
      ourCommitmentsDiscoverTimeline(
        ctx.current,
        ourCommitments.current,
        fonderia2StateHandler,
        magazzinoStateHandler,
        fonderia3StateHandler,
        rifiutiTrenoStateHandler,
        treniStateHandler,
        fabbrica1StateHandler,
        capannoneStateHandler,
        fabbrica2StateHandler,
        cantiereStateHandler,
        discoverPalliniStateHandler,
        scrollCompleteHandlerPropRef.current,
        progressHandlerRef.current
      );
    } else if (mapStateProp === "fighting-co2") {
      gsap.delayedCall(2, () => {
        setColoreElements("verde");
      });
      ourCommitmentsFightingCo2Timeline(
        ctx.current,
        ourCommitments.current,
        fonderia1StateHandler,
        fonderia2StateHandler,
        pannelliFonderiaStateHandler,
        fonderia3StateHandler,
        treniStateHandler,
        pannelliFabbricaStateHandler,
        fightingCo2PalliniStateHandler,
        scrollCompleteHandlerPropRef.current,
        progressHandlerRef.current
      );
    } else if (mapStateProp === "in-out") {
      ourCommitmentsInOutTimeline(
        ctx.current,
        ourCommitments.current,
        fonderia2StateHandler,
        fonderia3StateHandler,
        treniStateHandler,
        villaggioRosaStateHandler,
        inOutPalliniStateHandler,
        scrollCompleteHandlerPropRef.current,
        progressHandlerRef.current
      );
    } else if (mapStateProp === "investments") {
      setColoreElements("giallo");

      ourCommitmentsInvestmentsTimeline(
        ctx.current,
        ourCommitments.current,
        rottamiStateHandler,
        pannelliFonderiaDestraStateHandler,
        fonderia1StateHandler,
        fonderia2StateHandler,
        fonderia3StateHandler,
        pannelliFabbricaStateHandler,
        investmentsPalliniStateHandler,
        scrollCompleteHandlerPropRef.current,
        progressHandlerRef.current
      );
    } else if (mapStateProp === "the-future") {
      setColoreElements("azzurro");

      ourCommitmentsTheFutureTimeline(
        ctx.current,
        ourCommitments.current,
        pannelliFonderiaDestraStateHandler,
        parcoEolicoStateHandler,
        idrogenoStateHandler,
        fattoriaStateHandler,
        theFuturePalliniStateHandler,
        scrollCompleteHandlerPropRef.current,
        progressHandlerRef.current
      );
    } else if (mapStateProp === "initial") {
      gsap
        .timeline({
          onComplete: () => {
            ctx.current.revert();
          },
        })
        .to("#mappa-our-commitments-cont", {
          x: (100 * vw - 171.9 * vh) / 2 + 15 * vh,
          y: 0 * vh,
          scale: 1,
          force3D: true,
          duration: 0.6,
          overwrite: true,
        })
        .set(ourCommitments.current, { position: "fixed" });

      setColoreElements("bianco");

      setRottamiState("play");
      setFonderia1State("play");
      setFonderia2State("play");
      setMagazzinoState("play");
      setPannelliFonderiaState("play");
      setFonderia3State("play");
      setRifiutiTrenoState("play");
      setTreniState("play");
      setFabbrica1State("play");
      setCapannoneState("play");
      setFabbrica2State("play");
      setCantiereState("play");

      scrollCompleteHandlerPropRef.current(false);

      if (pannelliFabbricaStateRef.current === "play") {
        setPannelliFabbricaState("reverse");
      }
      if (villaggioRosaStateRef.current === "play") {
        setVillaggioRosaState("reverse");
      }
      if (pannelliFonderiaDestraStateRef.current === "play") {
        setPannelliFonderiaDestraState("reverse");
      }
      if (parcoEolicoStateRef.current === "play") {
        setParcoEolicoState("reverse");
      }
      if (idrogenoStateRef.current === "play") {
        setIdrogenoState("reverse");
      }
      if (fattoriaStateRef.current === "play") {
        setFattoriaState("reverse");
      }

      setColoreElements(coloreElementsRef.current);
      gsap.delayedCall(0.5, () => {
        setColoreElements("bianco");
      });

      setdiscoverPalliniState("initial");
      setFightingCo2PalliniState("initial");
      setInOutPalliniState("initial");
      setInvestmentsPalliniState("initial");
      setTheFuturePalliniState("initial");
    }
  }, [mapStateProp]);

  return (
    <div
      id="mappa-our-commitments"
      className="fixed-element"
      ref={ourCommitments}
    >
      <OurCommitmentsProgressBar
        mapStateProp={mapStateProp}
        progrssProp={progress}
        progressHandler={progressHandlerRef.current}
      />
      <div
        id="mappa-our-commitments-cont"
        className={fondoLoaded ? "fixed-element show" : "fixed-element hide"}
      >
        <OurCommitmentsPallini
          discoverPalliniStateProp={discoverPalliniState}
          fightingCo2PalliniStateProp={fightingCo2PalliniState}
          inOutPalliniStateProp={inOutPalliniState}
          investmentsPalliniStateProps={investmentsPalliniState}
          theFuturePalliniStateProps={theFuturePalliniState}
        />

        <OurCommitmentsNuvole />

        <OurCommitmentsElements
          coloreElementsProp={coloreElements}
          rottamiStateProp={rottamiState}
          fonderia1StateProp={fonderia1State}
          fonderia2StateProp={fonderia2State}
          magazzinoStateProp={magazzinoState}
          pannelliFonderiaStateProp={pannelliFonderiaState}
          fonderia3StateProp={fonderia3State}
          rifiutiTrenoStateProp={rifiutiTrenoState}
          treniStateProp={treniState}
          fabbrica1StateProp={fabbrica1State}
          capannoneStateProp={capannoneState}
          fabbrica2StateProp={fabbrica2State}
          cantiereStateProp={cantiereState}
          pannelliFabbricaStateProp={pannelliFabbricaState}
          villaggioRosaStateProp={villaggioRosaState}
          pannelliFonderiaDestraStateProp={pannelliFonderiaDestraState}
          parcoEolicoStateProp={parcoEolicoState}
          idrogenoStateProp={idrogenoState}
          fattoriaStateProp={fattoriaState}
        />

        <OurCommitmentsMezzi />

        <OurCommitmentsFondoMappa fondoLoadedHandlerProp={fondoLoadedHandler} />
      </div>
    </div>
  );
}

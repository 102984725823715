import OurCommitmentsDiscoverPallini from "./OurCommitmentsDiscoverPallini";
import OurCommitmentsFightingCo2Pallini from "./OurCommitmentsFightingCo2Pallini";
import OurCommitmentsInOutPallini from "./OurCommitmentsInOutPallini";
import OurCommitmentsInvestmentsPallini from "./OurCommitmentsInvestmentsPallini";
import OurCommitmentsTheFuturePallini from "./OurCommitmentsTheFuturePallini";

import "../../../css/pages/ourcommitments/our-commitments-pallini.scss";

export default function OurCommitmentsPallini({
  discoverPalliniStateProp,
  fightingCo2PalliniStateProp,
  inOutPalliniStateProp,
  investmentsPalliniStateProps,
  theFuturePalliniStateProps,
}) {
  return (
    <>
      <OurCommitmentsDiscoverPallini discoverState={discoverPalliniStateProp} />
      <OurCommitmentsFightingCo2Pallini
        fightingCo2State={fightingCo2PalliniStateProp}
      />
      <OurCommitmentsInOutPallini inOutState={inOutPalliniStateProp} />
      <OurCommitmentsInvestmentsPallini
        investmentsState={investmentsPalliniStateProps}
      />
      <OurCommitmentsTheFuturePallini
        theFutureState={theFuturePalliniStateProps}
      />
    </>
  );
}

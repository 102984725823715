import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { MotionPathPlugin } from "gsap/all";

import { vh } from "./globals";

export default function ourCommitmentsTheFutureTimeline(
  ctx,
  ourCommitments,
  pannelliFonderiaDestraStateHandler,
  parcoEolicoStateHandler,
  idrogenoStateHandler,
  fattoriaStateHandler,
  theFuturePalliniStateHandler,
  scrollCompleteHandler,
  progressHandler
) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);
  scrollCompleteHandler(false);

  ctx.add(() => {
    gsap
      .timeline()
      .set(ourCommitments, { position: "relative" })
      .to("#mappa-our-commitments-cont", {
        scale: 1.8,
        x: 100 * vh,
        y: 20 * vh,
        force3D: true,
        duration: 1.5,
        ease: "power3.inOut",
      })
      .add(() => {
        pannelliFonderiaDestraStateHandler("play");
        theFuturePalliniStateHandler(1);
      }, "-=0.2")
      .set(ourCommitments, { height: 500 * vh })
      .add(() => {
        const elementiPalliniTimeline = gsap
          .timeline()
          .add(() => {
            parcoEolicoStateHandler("play");
            theFuturePalliniStateHandler(2);
          }, 0.5)
          .add(() => {
            idrogenoStateHandler("play");
            theFuturePalliniStateHandler(3);
          }, 0.6)
          .add(() => {
            fattoriaStateHandler("play");
            theFuturePalliniStateHandler(4);
            scrollCompleteHandler(true);
          }, 0.78);

        gsap
          .timeline({
            defaults: { duration: 1 },
            scrollTrigger: {
              trigger: ourCommitments,
              start: 0,
              scrub: "1",
              onUpdate: (elementiPalliniTimeline) => {
                progressHandler(elementiPalliniTimeline.progress * 125 + "%");
              },
            },
          })
          .to("#mappa-our-commitments-cont", {
            motionPath: {
              path: [
                { x: "90vh", y: "20vh" }, //fonderia2
                { x: "50vh", y: "38vh" }, //eolico
                { x: "-30vh", y: "38vh" }, //fattoria
              ],
              curviness: 1,
            },
            ease: "none",
          })
          .add(elementiPalliniTimeline, 0);
      });
  });
}

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { MotionPathPlugin } from "gsap/all";

import { onerem, vh } from "./globals";

export default function ourCommitmentsFightingCo2Timeline(
  ctx,
  ourCommitments,
  fonderia1StateHandler,
  fonderia2StateHandler,
  pannelliFonderiaStateHandler,
  fonderia3StateHandler,
  treniStateHandler,
  pannelliFabbricaStateHandler,
  fightingCo2PalliniStateHandler,
  scrollCompleteHandler,
  progressHandler
) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);

  fonderia1StateHandler("reverse");
  fonderia2StateHandler("reverse");
  pannelliFonderiaStateHandler("reverse");
  fonderia3StateHandler("reverse");
  treniStateHandler("reverse");
  scrollCompleteHandler(false);

  ctx.add(() => {
    gsap
      .timeline()
      .set(ourCommitments, { position: "relative" })
      .to("#mappa-our-commitments-cont", {
        scale: 1.8,
        x: 100 * vh,
        y: 25 * vh + 0 * onerem,
        force3D: true,
        duration: 1.5,
        ease: "power3.inOut",
      })
      .add(() => {
        pannelliFonderiaStateHandler("play");
        fightingCo2PalliniStateHandler(1);
      }, "-=0.2")
      .set(ourCommitments, { height: 600 * vh })
      .add(() => {
        const elementiPalliniTimeline = gsap
          .timeline()
          .add(() => {
            fonderia1StateHandler("play");
            fightingCo2PalliniStateHandler(2);
          }, 0.035)
          .add(() => {
            fonderia2StateHandler("play");
            fightingCo2PalliniStateHandler(3);
          }, 0.16)
          .add(() => {
            fonderia3StateHandler("play");
            fightingCo2PalliniStateHandler(4);
          }, 0.2)
          .add(() => {
            pannelliFabbricaStateHandler("play");
          }, 0.39)
          .add(() => {
            treniStateHandler("play");
            fightingCo2PalliniStateHandler(5);
            scrollCompleteHandler(true);
          }, 0.8);

        gsap
          .timeline({
            defaults: { duration: 1 },
            scrollTrigger: {
              trigger: ourCommitments,
              start: 0,
              scrub: "1",
              onUpdate: (elementiPalliniTimeline) => {
                progressHandler(elementiPalliniTimeline.progress * 125 + "%");
              },
            },
          })
          .to("#mappa-our-commitments-cont", {
            motionPath: {
              path: [
                { x: "100vh", y: "25vh" }, //inizio
                { x: "85vh", y: "15vh + 25rem" }, //fonderia2
                { x: "55vh", y: "5vh + 25rem" }, //fonderia3
                { x: "10vh", y: "15vh" },
                { x: "0vh", y: "-60vh" }, //treno
              ],
              curviness: 1,
            },
            ease: "none",
          })
          .add(elementiPalliniTimeline, 0);
      });
  });
}

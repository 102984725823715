import PopUp from "../../../components/gsap/PopUp";

import magazzino from "../../../../assets/commitments/magazzino.png";

export default function OurCommitmentsMagazzino({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="magazzino" state={state} delay={delay + 0.3}>
        <img src={magazzino} alt="magazzino" />
      </PopUp>
    </>
  );
}

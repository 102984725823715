import FadeInUpPallino from "../../components/gsap/FadeInUpPallino";

import { ReactComponent as TriangoloTooltipOurCommitments } from "../../../assets/commitments/triangolo-tooltip-commit.svg";

export default function OurCommitmentsPallino({
  iconaTop,
  pallinoSateProp,
  nome,
  classe,
  titolo,
  testo,
  icone,
  discoverMoreLink,
}) {
  return (
    <div
      id={nome}
      className={
        pallinoSateProp === "play"
          ? "our-commitment-pallino play"
          : "our-commitment-pallino"
      }
    >
      <FadeInUpPallino state={pallinoSateProp}>
        <div className={`our-commitment-pallino-cont ${classe}`}>
          {iconaTop}
          <div className="our-commitments-tooltip">
            <div className="our-commitments-tooltip-cont">
              {iconaTop}
              <div className="our-commitments-tooltip-titolo">
                <h2>{titolo}</h2>
              </div>
              <div>{testo}</div>
              {icone ? icone : undefined}
              {discoverMoreLink ? discoverMoreLink : undefined}
            </div>
            <TriangoloTooltipOurCommitments />
          </div>
        </div>
      </FadeInUpPallino>
    </div>
  );
}

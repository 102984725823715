import PopUp from "../../../components/gsap/PopUp";

import fabbrica from "../../../../assets/commitments/fabbrica.png";
import containerCamion2 from "../../../../assets/commitments/container-camion-2.png";

export default function OurCommitmentsFabbrica1({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="fabbrica1" state={state} delay={delay}>
        <img src={fabbrica} alt="fabbrica" />
      </PopUp>
      <PopUp id="fabrica1ContainerCamion2" state={state} delay={delay + 0.3}>
        <img src={containerCamion2} alt="containerCamion2" />
      </PopUp>
    </>
  );
}

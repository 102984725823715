import PopUp from "../../../components/gsap/PopUp";

import trenoGrande from "../../../../assets/commitments/treno-grande.png";
import trenoPiccolo from "../../../../assets/commitments/treno-piccolo.png";

export default function OurCommitmentsTreni({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="trenoGrande" state={state} delay={delay}>
        <img src={trenoGrande} alt="trenoGrande" />
      </PopUp>
      <PopUp id="trenoPiccolo" state={state} delay={delay + 0.3}>
        <img src={trenoPiccolo} alt="trenoPiccolo" />
      </PopUp>
    </>
  );
}

import PopUp from "../../../components/gsap/PopUp";

import fondoIdrogeno from "../../../../assets/commitments/fondo-idrogeno.png";
import idrogeno from "../../../../assets/commitments/idrogeno.png";

export default function OurCommitmentsIdrogeno({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="fondoIdrogeno" state={state} delay={delay}>
        <img src={fondoIdrogeno} alt="idrogeno" />
      </PopUp>
      <PopUp id="idrogeno" state={state} delay={delay + 0.5}>
        <img src={idrogeno} alt="idrogeno" />
      </PopUp>
    </>
  );
}

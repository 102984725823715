import { useEffect, useLayoutEffect, useState } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import { vh } from "../../helpers/globals";

import Main from "../../layout/Main";
import OurCommitmentsMappa from "./OurCommitmentsMappa";
import OurCommitmentsSwitches from "./OurCommitmentsSwitches";
import TextWithBackground from "../../components/gsap/TextWithBackground";
import FadeInUp from "../../components/gsap/FadeInUp";

import "../../../css/pages/ourcommitments/our-commitments.scss";

export default function OurCommitments() {
  useEffect(() => {
    window.onbeforeunload = function (event) {
      ScrollTrigger.clearScrollMemory();
      window.history.scrollRestoration = "manual";
    };
  });

  const [scrollComplete, setScrollComplete] = useState();
  const scrollCompleteHandler = (scrollComplete) => {
    setScrollComplete(scrollComplete);
  };

  const [mapState, setMapState] = useState();
  const mapStateHandler = (mapState) => {
    setMapState(mapState);
  };

  useEffect(() => {
    if (mapState === "initial") {
      gsap.to(".our-commitments-titolo", {
        y: 0,
        duration: 0.8,
      });
    } else if (mapState && mapState !== "initial") {
      gsap.to(".our-commitments-titolo", {
        y: -40 * vh,
        duration: 0.8,
      });
    }
  }, [mapState]);

  useLayoutEffect(() => {
    window.addEventListener(
      "keydown",
      function (e) {
        if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
          e.preventDefault();
        }
      },
      false
    );
  }, []);

  return (
    <Main
      titolo="Value and Future"
      classe="our-commitments"
    >
      <article>
        <div className="our-commitments-titolo">
          <FadeInUp
            state={"play"}
            delay={"0.7"}
          >
            <TextWithBackground
              state={"play"}
              delay={"1.2"}
            >
              <h1 className="animating-text">
                <span>value</span> and <span>future</span>
              </h1>
            </TextWithBackground>
          </FadeInUp>
          <div className="our-commitments-text">
            <FadeInUp
              state={"play"}
              delay={"1.2"}
            >
              <p>
                Explore decarbonization, sustainability, and circular value chains in Europe's steel industry:
                <br />
                discover Feralpi group's model.
              </p>
            </FadeInUp>
          </div>
        </div>
        <OurCommitmentsMappa
          mapStateProp={mapState}
          scrollCompleteHandlerProp={scrollCompleteHandler}
        />
        <OurCommitmentsSwitches
          mapStateProp={mapState}
          mapStateHandlerProp={mapStateHandler}
          scrollCompleteProp={scrollComplete}
        />
      </article>
    </Main>
  );
}

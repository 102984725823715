import { useRef, useLayoutEffect } from "react";

import { gsap } from "gsap";

import camionRifiuti1 from "../../../assets/commitments/camion-rifiuti-1.png";
import camionRifiuti2 from "../../../assets/commitments/camion-rifiuti-2.png";
import camionRifiuti3 from "../../../assets/commitments/camion-rifiuti-3.png";
import camionRifiuti4 from "../../../assets/commitments/camion-rifiuti-4.png";

import camion1 from "../../../assets/commitments/camion-1.png";
import camion2 from "../../../assets/commitments/camion-2.png";
import camion3 from "../../../assets/commitments/camion-3.png";

import camionMerci1 from "../../../assets/commitments/camion-merci-1.png";
import camionMerci2 from "../../../assets/commitments/camion-merci-2.png";
import camionMerci3 from "../../../assets/commitments/camion-merci-3.png";

import "../../../css/pages/ourcommitments/our-commitments-mezzi.scss";

export default function OurCommitmentsMezzi() {
  const mezzi = useRef();
  const ctx = useRef();

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      const camionRifiutiEl = document.getElementById("camion-rifiuti");
      const camionRifiutiElImg = camionRifiutiEl.getElementsByTagName("img")[0];

      const camionEl = document.getElementById("camion");
      const camionElImg = camionEl.getElementsByTagName("img")[0];

      const camionMerciEl = document.getElementById("camion-merci");
      const camionMerciElImg = camionMerciEl.getElementsByTagName("img")[0];

      const camionRifiutiTimeline = gsap
        .timeline({ defaults: { ease: "none" }, paused: true, repeat: -1 })
        .to(camionRifiutiEl, { opacity: 1, duration: 0.5 })
        .to(
          camionRifiutiEl,
          {
            x: "+= 14.5vh",
            y: "+=8.3vh",
            duration: 2,
          },
          "<"
        )
        .set(camionRifiutiElImg, {
          attr: { src: camionRifiuti1 },
          x: "+= 2.5vh",
          y: "+= 1vh",
        })
        .to(camionRifiutiEl, {
          x: "+= 8.4vh",
          y: "-=5vh",
          duration: 1.5,
        })
        .set(camionRifiutiElImg, {
          attr: { src: camionRifiuti3 },
          x: "+= 3.5vh",
          y: "+= 1.3vh",
        })
        .set(camionRifiutiEl, {
          zIndex: 15,
        })
        .to(camionRifiutiEl, {
          x: "+= 43.9vh",
          y: "+=25.2vh",
          duration: 7,
        })
        .set(camionRifiutiEl, {
          zIndex: 13,
        })
        .set(camionRifiutiElImg, {
          attr: { src: camionRifiuti4 },
          x: "-=1.2vh",
          y: "+=0.5vh",
        })
        .to(camionRifiutiEl, {
          x: "-=38.6vh",
          y: "+=22.2vh",
          duration: 6,
        })
        .set(camionRifiutiElImg, {
          attr: { src: camionRifiuti2 },
          x: "-=3vh",
          y: "+=0vh",
        })
        .to(camionRifiutiEl, {
          x: "-=19vh",
          y: "-=10.8vh",
          duration: 3,
        })
        .set(camionRifiutiElImg, {
          attr: { src: camionRifiuti4 },
          x: "-=1.9vh",
          y: "-=1.5vh",
        })
        .to(camionRifiutiEl, {
          x: "-=24.2vh",
          y: "+=14.1vh",
          duration: 3.5,
        })
        .set(camionRifiutiElImg, {
          attr: { src: camionRifiuti2 },
          x: "-=2.5vh",
          y: "+=1.3vh",
        })
        .to(camionRifiutiEl, {
          x: "-=20vh",
          y: "-=11.4vh",
          duration: 5,
          ease: "power3.Out",
        })
        .to(camionRifiutiEl, {
          x: "-=0",
          y: "-=0",
          duration: 5,
        })
        .to(camionRifiutiEl, {
          opacity: "0",
          duration: 0.5,
        });

      const camionTimeline = gsap
        .timeline({ defaults: { ease: "none" }, paused: true, repeat: -1 })
        .set(camionEl, {
          zIndex: 14,
        })
        .to(camionEl, { opacity: 1, duration: 0.5 })
        .to(
          camionEl,
          {
            x: "+=27.5vh",
            y: "+=16.1vh",
            duration: 6,
          },
          "<"
        )
        .set(camionElImg, {
          attr: { src: camion1 },
          x: "+= 3vh",
          y: "+= 1.3vh",
        })
        .to(camionEl, {
          x: "+= 24.5vh",
          y: "-=13.9vh",
          duration: 5,
        })
        .set(camionElImg, {
          attr: { src: camion2 },
          x: "+= 1.5vh",
          y: "-= 1.5vh",
        })
        .to(camionEl, {
          x: "+= 21.2vh",
          y: "+=12.3vh",
          duration: 5,
        })
        .set(camionElImg, {
          attr: { src: camion1 },
          x: "+= 1.7vh",
          y: "+= 1.5vh",
        })
        .to(camionEl, {
          x: "+= 42.2vh",
          y: "-=24.5vh",
          duration: 8,
        })
        .set(camionElImg, {
          attr: { src: camion3 },
          x: "+= 0.5vh",
          y: "+= 0.5vh",
        })
        .to(camionEl, {
          x: "-= 27vh",
          y: "-=15.3vh",
          duration: 6,
          ease: "power2.Out",
        })
        .to(camionEl, {
          x: "-=0",
          y: "-=0",
          duration: 5,
        })
        .to(camionEl, {
          opacity: "0",
          duration: 0.5,
        });

      const camionMerciTimeline = gsap
        .timeline({ defaults: { ease: "none" }, paused: true, repeat: -1 })
        .to(camionMerciEl, { opacity: 1, duration: 0.5 })
        .to(
          camionMerciEl,
          {
            x: "-=33.9vh",
            y: "+=19.4vh",
            duration: 4,
          },
          "<"
        )
        .set(camionMerciElImg, {
          attr: { src: camionMerci2 },
          x: "-= 0.5vh",
          y: "+= 1vh",
        })
        .to(camionMerciEl, {
          x: "+=18.5vh",
          y: "+=10.5vh",
          duration: 3,
        })
        .set(camionMerciElImg, {
          attr: { src: camionMerci1 },
          x: "+= 1vh",
          y: "+= 1vh",
        })
        .to(camionMerciEl, {
          x: "+=9.3vh",
          y: "-=5vh",
          duration: 1.5,
        })
        .set(camionMerciElImg, {
          attr: { src: camionMerci2 },
          x: "+= 0.5vh",
          y: "-= 0.5vh",
        })
        .to(camionMerciEl, {
          x: "+=16.2vh",
          y: "+=8.7vh",
          duration: 2,
        })
        .set(camionMerciElImg, {
          attr: { src: camionMerci1 },
          x: "+= 3vh",
          y: "-= 0.5vh",
        })
        .to(camionMerciEl, {
          x: "+=104.4vh",
          y: "-=60vh",
          duration: 14,
        });

      camionRifiutiTimeline.play(7);
      camionTimeline.play();
      camionMerciTimeline.play();
    }, mezzi);

    return () => ctx.current.revert();
  }, []);

  return (
    <section className="our-commitments-mezzi page-change-up" ref={mezzi}>
      <div id="camion-rifiuti" className="mezzo">
        <img src={camionRifiuti3} alt="camion" />
      </div>
      <div id="camion" className="mezzo">
        <img src={camion2} alt="camion" />
      </div>
      <div id="camion-merci" className="mezzo">
        <img src={camionMerci3} alt="camion" />
      </div>
    </section>
  );
}

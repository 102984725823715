import PopUp from "../../../components/gsap/PopUp";

import gabbiotto from "../../../../assets/commitments/gabbiotto.png";
import fabbrica from "../../../../assets/commitments/fabbrica.png";
import camionRimorchio from "../../../../assets/commitments/camion-rimorchio.png";

export default function OurCommitmentsFabbrica2({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="fabbrica2gabbiotto" state={state} delay={delay}>
        <img src={gabbiotto} alt="gabbiotto" />
      </PopUp>
      <PopUp id="fabbrica2" state={state} delay={delay + 0.3}>
        <img src={fabbrica} alt="fabbrica" />
      </PopUp>
      <PopUp id="fabrica2CamionRimorchio" state={state} delay={delay + 0.6}>
        <img src={camionRimorchio} alt="camionRimorchio" />
      </PopUp>
    </>
  );
}

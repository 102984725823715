import PopUp from "../../../components/gsap/PopUp";

import fattoria from "../../../../assets/commitments/fattoria.png";

export default function OurCommitmentsFattoria({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="fattoria" state={state} delay={delay}>
        <img src={fattoria} alt="fattoria" />
      </PopUp>
    </>
  );
}

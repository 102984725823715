import fondoMappaGrande from "../../../assets/commitments/fondo-mappa.webp";
import palazzo from "../../../assets/commitments/palazzo.png";
import casetta from "../../../assets/commitments/casetta.png";
import casettaPannelli from "../../../assets/commitments/casetta-pannelli.png";

import palazzoRosa from "../../../assets/commitments/palazzo-rosa.png";
import casettaRosa from "../../../assets/commitments/casetta-rosa.png";
import casettaRosaPannelli from "../../../assets/commitments/casetta-rosa-pannelli.png";

export default function OurCommitmentsFondoMappa({
  coloreElementsProp,
  fondoLoadedHandlerProp,
}) {
  return (
    <div className="fondo-mappa page-change-up">
      <img
        src={fondoMappaGrande}
        alt="fondoMappa"
        onLoad={() => {
          fondoLoadedHandlerProp(true);
        }}
      />
      <div
        id="villaggio1"
        style={{
          backgroundImage: `url(${palazzoRosa})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          style={coloreElementsProp === "rosa" ? { opacity: "0" } : {}}
          src={palazzo}
          alt="casetta"
        />
      </div>

      <div
        id="villaggio2"
        style={{
          backgroundImage: `url(${casettaRosaPannelli})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          style={coloreElementsProp === "rosa" ? { opacity: "0" } : {}}
          src={casettaPannelli}
          alt="casetta"
        />
      </div>

      <div
        id="villaggio3"
        style={{
          backgroundImage: `url(${casettaRosaPannelli})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          style={coloreElementsProp === "rosa" ? { opacity: "0" } : {}}
          src={casettaPannelli}
          alt="casetta"
        />
      </div>

      <div
        id="villaggio4"
        style={{
          backgroundImage: `url(${casettaRosa})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          style={coloreElementsProp === "rosa" ? { opacity: "0" } : {}}
          src={casetta}
          alt="casetta"
        />
      </div>
    </div>
  );
}

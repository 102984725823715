import FadeInUp from "../../../components/gsap/FadeInUp";

import palazzoRosa from "../../../../assets/commitments/palazzo-rosa.png";
import casettaRosa from "../../../../assets/commitments/casetta-rosa.png";
import casettaRosaPannelli from "../../../../assets/commitments/casetta-rosa-pannelli.png";

export default function OurCommitmentsVillaggioRosa({ state, delay = 0 }) {
  return (
    <>
      <div id="villaggio-rosa1">
        <FadeInUp state={state} delay={delay}>
          <img src={palazzoRosa} alt="palazzo" />
        </FadeInUp>
      </div>
      <div id="villaggio-rosa2">
        <FadeInUp state={state} delay={delay + 0.3}>
          <img src={casettaRosa} alt="casetta" />
        </FadeInUp>
      </div>

      <div id="villaggio-rosa3">
        <FadeInUp state={state} delay={delay + 0.6}>
          <img src={casettaRosaPannelli} alt="casetta" />
        </FadeInUp>
      </div>

      <div id="villaggio-rosa4">
        <FadeInUp state={state} delay={delay + 0.9}>
          <img src={casettaRosaPannelli} alt="casetta" />
        </FadeInUp>
      </div>
    </>
  );
}

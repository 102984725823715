import PopUp from "../../../components/gsap/PopUp";

import capannone from "../../../../assets/commitments/capannone.png";
import containerCamion from "../../../../assets/commitments/container-camion.png";

export default function OurCommitmentsCapannone({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="capannone" state={state} delay={delay}>
        <img src={capannone} alt="capannone" />
      </PopUp>
      <PopUp id="capannoneContainerCamion" state={state} delay={delay + 0.3}>
        <img src={containerCamion} alt="containerCamion" />
      </PopUp>
    </>
  );
}

import PopUp from "../../../components/gsap/PopUp";

import fondoParcoEolico from "../../../../assets/commitments/fondo-parco-eolico.png";
import palaEolica from "../../../../assets/commitments/pala-eolica.png";

export default function OurCommitmentsParcoEolico({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="fondoParcoEolico" state={state} delay={delay}>
        <img src={fondoParcoEolico} alt="parco eolico" />
      </PopUp>
      <PopUp id="palaEolica1" state={state} delay={delay + 0.5}>
        <img src={palaEolica} alt="parco eolico" />
      </PopUp>
      <PopUp id="palaEolica2" state={state} delay={delay + 0.8}>
        <img src={palaEolica} alt="parco eolico" />
      </PopUp>
      <PopUp id="palaEolica3" state={state} delay={delay + 1.1}>
        <img src={palaEolica} alt="parco eolico" />
      </PopUp>
      <PopUp id="palaEolica4" state={state} delay={delay + 1.4}>
        <img src={palaEolica} alt="parco eolico" />
      </PopUp>
    </>
  );
}

import { useRef, useLayoutEffect } from "react";

import { gsap } from "gsap";

import { vw, vh } from "../../helpers/globals";

import nuvole1 from "../../../assets/commitments/nuvole1.png";
import nuvole2 from "../../../assets/commitments/nuvole2.png";
import nuvole3 from "../../../assets/commitments/nuvole3.png";
import nuvole4 from "../../../assets/commitments/nuvole4.png";

import "../../../css/pages/ourcommitments/our-commitments-nuvole.scss";

export default function OurCommitmentsNuvole() {
  const nuvole = useRef();
  const ctx = useRef();

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      const nuvola1Timeline = gsap
        .timeline({ paused: true, repeat: -1 })
        .fromTo(
          "#nuvola1",
          {
            x: -25 * vw,
            y: 20 * vh,
          },
          {
            x: 40 * vw,
            y: -58 * vh,
            duration: 40,
            ease: "none",
          }
        );
      const nuvola2Timeline = gsap
        .timeline({ paused: true, repeat: -1 })
        .fromTo(
          "#nuvola2",
          {
            x: -35 * vw,
            y: 30 * vh,
          },
          {
            x: 50 * vw,
            y: -73 * vh,
            duration: 60,
            ease: "none",
          }
        );
      const nuvola3Timeline = gsap
        .timeline({ paused: true, repeat: -1 })
        .fromTo(
          "#nuvola3",
          {
            x: -45 * vw,
            y: 40 * vh,
          },
          {
            x: 50 * vw,
            y: -73 * vh,
            duration: 60,
            ease: "none",
          }
        );
      const nuvola4Timeline = gsap
        .timeline({ paused: true, repeat: -1 })
        .fromTo(
          "#nuvola4",
          {
            x: -65 * vw,
            y: 70 * vh,
          },
          {
            x: 40 * vw,
            y: -58 * vh,
            duration: 80,
            ease: "none",
          }
        );

      gsap.to("#nuvola1, #nuvola2,#nuvola3,#nuvola4", {
        autoAlpha: 0.9,
        duration: 2,
        delay: 3,
      });
      nuvola1Timeline.play(15);
      nuvola2Timeline.play(10);
      nuvola3Timeline.play(35);
      nuvola4Timeline.play(40);
    }, nuvole);

    return () => ctx.current.revert();
  }, []);

  return (
    <section className="our-commitments-nuvole" ref={nuvole}>
      <div id="nuvola1">
        <img src={nuvole1} alt="nuvole" />
      </div>
      <div id="nuvola2">
        <img src={nuvole2} alt="nuvole" />
      </div>
      <div id="nuvola3">
        <img src={nuvole3} alt="nuvole" />
      </div>
      <div id="nuvola4">
        <img src={nuvole4} alt="nuvole" />
      </div>
    </section>
  );
}

import { useEffect, useState, Fragment } from "react";

import OurCommitmentsPallino from "./OurCommitmentsPallino";

import { ReactComponent as IconaCircolare } from "../../../assets/commitments/icona-circolare.svg";
import { ReactComponent as IconaFrecce } from "../../../assets/commitments/icona-frecce.svg";
import { ReactComponent as IconaFrecceInterne } from "../../../assets/commitments/icona-frecce-interne.svg";
import refractary from "../../../assets/commitments/refractary.png";

import "../../../css/pages/ourcommitments/our-commitments-inout-pallini.scss";

import millScale1 from "../../../assets/commitments/mill-scale-1.png";

import { ReactComponent as FreccinaComm } from "../../../assets/commitments/freccina-comm.svg";
import millScale2 from "../../../assets/commitments/mill-scale-2.png";

import whiteSlag1 from "../../../assets/commitments/white-slag.png";
import whiteSlag2 from "../../../assets/commitments/white-slag-2.png";
import blackSlag1 from "../../../assets/commitments/black-slag.png";
import blackSlag2 from "../../../assets/commitments/black-slag-2.png";
import dustGasses1 from "../../../assets/commitments/dust-and-gasses.png";
import dustGasses2 from "../../../assets/commitments/dust-and-gasses-2.png";

import bioLubrificant from "../../../assets/commitments/bio-lubrificant.png";

import polymers from "../../../assets/commitments/polymers.png";
import rottami from "../../../assets/commitments/rottami.png";

import district from "../../../assets/commitments/district.png";

export default function OurCommitmentsInOutPallini({ inOutState }) {
  const [inOut1State, setinOut1State] = useState();
  const [inOut2State, setinOut2State] = useState();
  const [inOut3State, setinOut3State] = useState();
  const [inOut4State, setinOut4State] = useState();
  const [inOut5State, setinOut5State] = useState();
  const [inOut6State, setinOut6State] = useState();
  const [inOut7State, setinOut7State] = useState();
  const [inOut8State, setinOut8State] = useState();

  useEffect(() => {
    if (inOutState === 1) {
      setinOut1State("play");
    }
    if (inOutState === 2) {
      setinOut2State("play");
    }
    if (inOutState === 3) {
      setinOut3State("play");
    }
    if (inOutState === 4) {
      setinOut4State("play");
    }
    if (inOutState === 5) {
      setinOut5State("play");
    }
    if (inOutState === 6) {
      setinOut6State("play");
    }
    if (inOutState === 7) {
      setinOut7State("play");
    }
    if (inOutState === 8) {
      setinOut8State("play");
    }
    if (inOutState === "initial") {
      setinOut1State("reverse");
      setinOut2State("reverse");
      setinOut3State("reverse");
      setinOut4State("reverse");
      setinOut5State("reverse");
      setinOut6State("reverse");
      setinOut7State("reverse");
      setinOut8State("reverse");
    }
  }, [inOutState]);
  return (
    <>
      <OurCommitmentsPallino
        pallinoSateProp={inOut1State}
        iconaTop={<IconaCircolare />}
        nome={"inOut1"}
        classe={"pallino-inout"}
        titolo={"WE RECYCLE FOR A MORE SUSTAINABLE PROCESS"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={rottami} alt="scrap" />
              </div>
              <h3>FERROUS SCRAP</h3>
            </div>
            <p>Scrap recovery: the beginning of a circular journey</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut2State}
        iconaTop={<IconaCircolare />}
        nome={"inOut2"}
        classe={"pallino-inout"}
        titolo={"OUR WASTE\nFOR A NEW LIFE"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={refractary} alt="refractary" />
              </div>
              <h3>refractary materials</h3>
            </div>
            <p>
              Exhausted refractories from ladle demolition are reintroduced into
              the production cycle as partial substitutes for raw materials
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut3State}
        iconaTop={<IconaFrecceInterne />}
        nome={"inOut3"}
        classe={"pallino-inout"}
        titolo={"WE RECYCLE FOR A MORE SUSTAINABLE PROCESS"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={polymers} alt="polymers" />
              </div>
              <h3>polymers</h3>
            </div>
            <p>
              We recover polymers from recycled plastic into the electric
              furnace as an almost complete replacement for coal and its
              <nobr>by-products</nobr>
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut4State}
        iconaTop={<IconaFrecce />}
        nome={"inOut4"}
        classe={"pallino-inout"}
        titolo={"OUR WASTE\nFOR A NEW LIFE"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={whiteSlag1} alt="refractary" />
              </div>
              <div>
                <h3>white slag</h3>
                <p>
                  <FreccinaComm />
                  green lime
                </p>
              </div>
              <div className="tooltip-icona-testo-icona secondo">
                <img src={whiteSlag2} alt="refractary" />
              </div>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={blackSlag1} alt="refractary" />
              </div>
              <div>
                <h3>black slag</h3>
                <p>
                  <FreccinaComm />
                  green stone
                </p>
              </div>
              <div className="tooltip-icona-testo-icona secondo">
                <img src={blackSlag2} alt="refractary" />
              </div>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={dustGasses1} alt="refractary" />
              </div>
              <div>
                <h3>
                  dust and<br></br>off gases
                </h3>
                <p>
                  <FreccinaComm />
                  zinc oxide
                </p>
              </div>
              <div className="tooltip-icona-testo-icona secondo">
                <img src={dustGasses2} alt="refractary" />
              </div>
            </div>
            <p>
              Slags and emissions from the smelting process are recovered for
              other uses in various industries
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut5State}
        iconaTop={<IconaFrecceInterne />}
        nome={"inOut5"}
        classe={"pallino-inout"}
        titolo={"WE RECYCLE FOR A MORE SUSTAINABLE PROCESS"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={bioLubrificant} alt="bio lubrificant" />
              </div>
              <h3>Bio Lubrificants</h3>
            </div>
            <p>
              The use of recovered <nobr>vegetable-based</nobr> oils represents
              a circular action within the rolling process
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut6State}
        iconaTop={<IconaFrecce />}
        nome={"inOut6"}
        classe={"pallino-inout"}
        titolo={"OUR WASTE\nFOR A NEW LIFE"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={millScale1} alt="refractary" />
              </div>
              <div>
                <h3>mill scale</h3>
                <p>
                  <FreccinaComm />
                  green iron
                </p>
              </div>
              <div className="tooltip-icona-testo-icona secondo">
                <img src={millScale2} alt="refractary" />
              </div>
            </div>
            <p>
              Green Iron is the <nobr>by-products</nobr> from the rolling mill
              scale: it will be employed in the production of counterweights and
              cement
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut7State}
        iconaTop={<IconaFrecceInterne />}
        nome={"inOut7"}
        classe={"pallino-inout"}
        titolo={"WE RECYCLE FOR A MORE SUSTAINABLE PROCESS"}
        testo={
          <Fragment>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <img src={bioLubrificant} alt="bio lubrificant" />
              </div>
              <h3>Bio Lubrificants</h3>
            </div>
            <p>
              The use of recovered <nobr>vegetable-based</nobr> oils represents
              a circular action within the rolling process
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={inOut8State}
        iconaTop={<IconaFrecce />}
        nome={"inOut8"}
        classe={"pallino-inout"}
        titolo={"OUR WASTE\nFOR A NEW LIFE"}
        testo={
          <Fragment>
            <img src={district} alt="energy and district heating" />
            <h3>energy and district heating </h3>
            <p>
              We recover heat from the cooling waters of the steel mills,
              avoiding its dispersion and sharing the benefits with the
              community
            </p>
          </Fragment>
        }
      />
    </>
  );
}

import PopUp from "../../../components/gsap/PopUp";

import basePannelli from "../../../../assets/commitments/base-pannelli.png";
import pannelloVerticaleAzzurroSx from "../../../../assets/commitments/pannello-verticale-azzurro-sx.png";
import pannelloVerticaleGialloSx from "../../../../assets/commitments/pannello-verticale-giallo-sx.png";

export default function OurCommitmentsPannelliFonderiaDestra({
  state,
  color,
  delay = 0,
}) {
  return (
    <>
      <PopUp id="basePannelliFonderiaDestra" state={state} delay={delay}>
        <img src={basePannelli} alt="basePannelli" />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra1"
        state={state}
        delay={delay + 0.5}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra2"
        state={state}
        delay={delay + 0.55}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra3"
        state={state}
        delay={delay + 0.6}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra4"
        state={state}
        delay={delay + 0.65}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra5"
        state={state}
        delay={delay + 0.7}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra6"
        state={state}
        delay={delay + 0.75}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra7"
        state={state}
        delay={delay + 0.8}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra8"
        state={state}
        delay={delay + 0.85}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderiaDestra9"
        state={state}
        delay={delay + 0.9}
      >
        <img
          src={
            color === "giallo"
              ? pannelloVerticaleGialloSx
              : pannelloVerticaleAzzurroSx
          }
          alt="pannelloVerticaleAzzurroSx"
        />
      </PopUp>
    </>
  );
}

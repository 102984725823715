import { useEffect, useState, Fragment } from "react";

import OurCommitmentsPallino from "./OurCommitmentsPallino";

import { ReactComponent as IconaEolico } from "../../../assets/commitments/icona-eolico.svg";
import { ReactComponent as IconaPannelloAzzurro } from "../../../assets/commitments/icona-pannello-azzurro.svg";
import { ReactComponent as IconaIdrogeno } from "../../../assets/commitments/icona-idrogeno.svg";
import { ReactComponent as IconaTrattore } from "../../../assets/commitments/icona-trattore.svg";

import "../../../css/pages/ourcommitments/our-commitments-thefuture-pallini.scss";

export default function OurCommitmentsTheFuturePallini({ theFutureState }) {
  const [theFuture1State, setTheFuture1State] = useState();
  const [theFuture2State, setTheFuture2State] = useState();
  const [theFuture3State, setTheFuture3State] = useState();
  const [theFuture4State, setTheFuture4State] = useState();

  useEffect(() => {
    if (theFutureState === 1) {
      setTheFuture1State("play");
    }
    if (theFutureState === 2) {
      setTheFuture2State("play");
    }
    if (theFutureState === 3) {
      setTheFuture3State("play");
    }
    if (theFutureState === 4) {
      setTheFuture4State("play");
    }
    if (theFutureState === "initial") {
      setTheFuture1State("reverse");
      setTheFuture2State("reverse");
      setTheFuture3State("reverse");
      setTheFuture4State("reverse");
    }
  }, [theFutureState]);
  return (
    <>
      <OurCommitmentsPallino
        pallinoSateProp={theFuture1State}
        iconaTop={<IconaPannelloAzzurro />}
        nome={"theFuture1"}
        classe={"pallino-thefuture"}
        titolo={"NEW PHOTOVOLTAIC PARKS"}
        testo={
          <Fragment>
            <p>
              Over 200 million euros to produce renewable energy for
              self-consumption: initiatives include the creation of new
              photovoltaic parks
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={theFuture2State}
        iconaTop={<IconaEolico />}
        nome={"theFuture2"}
        classe={"pallino-thefuture"}
        titolo={"WIND PARKS"}
        testo={
          <Fragment>
            <p>
              We are studying the possibility of also investing in wind farms
              (particularly in Germany) to increase our energy mix
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={theFuture3State}
        iconaTop={<IconaIdrogeno />}
        nome={"theFuture3"}
        classe={"pallino-thefuture"}
        titolo={"HYDROGEN"}
        testo={
          <Fragment>
            <p>
              In Germany, we joined the Alliance for Energy and Hydrogen in the
              Meissen Industrial Area (EWI), with the aim of making hydrogen
              usable as an energy
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={theFuture4State}
        iconaTop={<IconaTrattore />}
        nome={"theFuture4"}
        classe={"pallino-thefuture"}
        titolo={"AGRICULTURAL BIOMETHANE"}
        testo={
          <Fragment>
            <p>
              In Italy we are part of Green Metals: the aim is to contribute to
              decarbonisation through the construction and reconversion of
              plants for the production of agricultural biomethane
            </p>
          </Fragment>
        }
      />
    </>
  );
}

import PopUp from "../../../components/gsap/PopUp";

import baseRifiutiTreno from "../../../../assets/commitments/base-rifiuti-treno.png";
import rottami2 from "../../../../assets/commitments/rottami-2.png";

export default function OurCommitmentsRifiutiTreno({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="baseRifiutiTreno" state={state} delay={delay}>
        <img src={baseRifiutiTreno} alt="baseRifiutiTreno" />
      </PopUp>
      <PopUp id="rifiutiTrenoRottami1" state={state} delay={delay + 0.3}>
        <img src={rottami2} alt="rottami" />
      </PopUp>
      <PopUp id="rifiutiTrenoRottami2" state={state} delay={delay + 0.6}>
        <img src={rottami2} alt="rottami" />
      </PopUp>
      <PopUp id="rifiutiTrenoRottami3" state={state} delay={delay + 0.9}>
        <img src={rottami2} alt="rottami" />
      </PopUp>
    </>
  );
}

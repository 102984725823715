import PopUp from "../../../components/gsap/PopUp";

import pannelloVerticaleVerdeDx from "../../../../assets/commitments/pannello-verticale-verde-dx.png";
import pannelloVerticaleAzzurroDx from "../../../../assets/commitments/pannello-verticale-azzurro-dx.png";
import pannelloVerticaleGialloDx from "../../../../assets/commitments/pannello-verticale-giallo-dx.png";

import pannelloInclinatoVerde from "../../../../assets/commitments/pannello-inclinato-verde.png";
import pannelloInclinatoAzzurro from "../../../../assets/commitments/pannello-inclinato-azzurro.png";
import pannelloInclinatoGiallo from "../../../../assets/commitments/pannello-inclinato-giallo.png";

export default function OurCommitmentsPannelliFabbrica({
  state,
  color,
  delay = 0,
}) {
  return (
    <>
      <PopUp id="pannelloFabbrica1Verde1" state={state} delay={delay}>
        <img
          src={
            color === "azzurro"
              ? pannelloInclinatoAzzurro
              : color === "giallo"
              ? pannelloInclinatoGiallo
              : pannelloInclinatoVerde
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloFabbrica1Verde2" state={state} delay={delay + 0.05}>
        <img
          src={
            color === "azzurro"
              ? pannelloInclinatoAzzurro
              : color === "giallo"
              ? pannelloInclinatoGiallo
              : pannelloInclinatoVerde
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloFabbrica1Verde3" state={state} delay={delay + 0.1}>
        <img
          src={
            color === "azzurro"
              ? pannelloInclinatoAzzurro
              : color === "giallo"
              ? pannelloInclinatoGiallo
              : pannelloInclinatoVerde
          }
          alt="pannello solare"
        />
      </PopUp>

      <PopUp id="pannelloCapannoneVerde1" state={state} delay={delay + 0.35}>
        <img
          src={
            color === "azzurro"
              ? pannelloVerticaleAzzurroDx
              : color === "giallo"
              ? pannelloVerticaleGialloDx
              : pannelloVerticaleVerdeDx
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloCapannoneVerde2" state={state} delay={delay + 0.4}>
        <img
          src={
            color === "azzurro"
              ? pannelloVerticaleAzzurroDx
              : color === "giallo"
              ? pannelloVerticaleGialloDx
              : pannelloVerticaleVerdeDx
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloCapannoneVerde3" state={state} delay={delay + 0.45}>
        <img
          src={
            color === "azzurro"
              ? pannelloVerticaleAzzurroDx
              : color === "giallo"
              ? pannelloVerticaleGialloDx
              : pannelloVerticaleVerdeDx
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloCapannoneVerde4" state={state} delay={delay + 0.5}>
        <img
          src={
            color === "azzurro"
              ? pannelloVerticaleAzzurroDx
              : color === "giallo"
              ? pannelloVerticaleGialloDx
              : pannelloVerticaleVerdeDx
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloCapannoneVerde5" state={state} delay={delay + 0.55}>
        <img
          src={
            color === "azzurro"
              ? pannelloVerticaleAzzurroDx
              : color === "giallo"
              ? pannelloVerticaleGialloDx
              : pannelloVerticaleVerdeDx
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloCapannoneVerde6" state={state} delay={delay + 0.6}>
        <img
          src={
            color === "azzurro"
              ? pannelloVerticaleAzzurroDx
              : color === "giallo"
              ? pannelloVerticaleGialloDx
              : pannelloVerticaleVerdeDx
          }
          alt="pannello solare"
        />
      </PopUp>

      <PopUp id="pannelloFabbrica2Verde1" state={state} delay={delay + 0.8}>
        <img
          src={
            color === "azzurro"
              ? pannelloInclinatoAzzurro
              : color === "giallo"
              ? pannelloInclinatoGiallo
              : pannelloInclinatoVerde
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloFabbrica2Verde2" state={state} delay={delay + 0.85}>
        <img
          src={
            color === "azzurro"
              ? pannelloInclinatoAzzurro
              : color === "giallo"
              ? pannelloInclinatoGiallo
              : pannelloInclinatoVerde
          }
          alt="pannello solare"
        />
      </PopUp>
      <PopUp id="pannelloFabbrica3Verde3" state={state} delay={delay + 0.95}>
        <img
          src={
            color === "azzurro"
              ? pannelloInclinatoAzzurro
              : color === "giallo"
              ? pannelloInclinatoGiallo
              : pannelloInclinatoVerde
          }
          alt="pannello solare"
        />
      </PopUp>
    </>
  );
}

import PopUp from "../../../components/gsap/PopUp";

import specialities from "../../../../assets/commitments/specialities.png";

export default function OurCommitmentsFonderia3({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="specialities" state={state} delay={delay}>
        <img src={specialities} alt="specialities" />
      </PopUp>
    </>
  );
}

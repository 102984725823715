import PopUp from "../../../components/gsap/PopUp";

import ponte from "../../../../assets/commitments/ponte.png";
import rullo from "../../../../assets/commitments/rullo.png";
import gru from "../../../../assets/commitments/gru.png";
import palazzoCostruzione from "../../../../assets/commitments/palazzo-costruzione.png";
import gabbiottoFlip from "../../../../assets/commitments/gabbiotto-flip.png";
import spalatrice from "../../../../assets/commitments/spalatrice.png";
import personeCantiere from "../../../../assets/commitments/persone-cantiere.png";

export default function OurCommitmentsCantiere({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="ponte" state={state} delay={delay}>
        <img src={ponte} alt="ponte" />
      </PopUp>
      <PopUp id="rullo" state={state} delay={delay + 0.3}>
        <img src={rullo} alt="rullo" />
      </PopUp>
      <PopUp id="gru" state={state} delay={delay + 0.5}>
        <img src={gru} alt="gru" />
      </PopUp>
      <PopUp id="palazzoCostruzione" state={state} delay={delay + 0.65}>
        <img src={palazzoCostruzione} alt="palazzoCostruzione" />
      </PopUp>
      <PopUp id="gabbiottoCantiere" state={state} delay={delay + 0.7}>
        <img src={gabbiottoFlip} alt="gabbiotto" />
      </PopUp>
      <PopUp id="spalatrice" state={state} delay={delay + 0.75}>
        <img src={spalatrice} alt="spalatrice" />
      </PopUp>
      <PopUp id="personeCantiere" state={state} delay={delay + 0.8}>
        <img src={personeCantiere} alt="personeCantiere" />
      </PopUp>
    </>
  );
}

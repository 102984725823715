import { useEffect, useState, Fragment } from "react";

import OurCommitmentsPallino from "./OurCommitmentsPallino";

import { ReactComponent as IconaDiscover } from "../../../assets/commitments/icona-discover.svg";

import "../../../css/pages/ourcommitments/our-commitments-discover-pallini.scss";

export default function OurCommitmentsDiscoverPallini({ discoverState }) {
  const [discover1State, setDiscover1State] = useState();
  const [discover2State, setDiscover2State] = useState();
  const [discover3State, setDiscover3State] = useState();
  const [discover4State, setDiscover4State] = useState();
  const [discover5State, setDiscover5State] = useState();
  const [discover6State, setDiscover6State] = useState();
  const [discover7State, setDiscover7State] = useState();
  const [discover8State, setDiscover8State] = useState();
  const [discover9State, setDiscover9State] = useState();
  const [discover10State, setDiscover10State] = useState();
  const [discover11State, setDiscover11State] = useState();

  useEffect(() => {
    if (discoverState === 1) {
      setDiscover1State("play");
    }
    if (discoverState === 2) {
      setDiscover2State("play");
    }
    if (discoverState === 3) {
      setDiscover3State("play");
    }
    if (discoverState === 4) {
      setDiscover4State("play");
    }
    if (discoverState === 5) {
      setDiscover5State("play");
    }
    if (discoverState === 6) {
      setDiscover6State("play");
    }
    if (discoverState === 7) {
      setDiscover7State("play");
    }
    if (discoverState === 8) {
      setDiscover8State("play");
    }
    if (discoverState === 9) {
      setDiscover9State("play");
    }
    if (discoverState === 10) {
      setDiscover10State("play");
    }
    if (discoverState === 11) {
      setDiscover11State("play");
    }
    if (discoverState === "initial") {
      setDiscover1State("reverse");
      setDiscover2State("reverse");
      setDiscover3State("reverse");
      setDiscover4State("reverse");
      setDiscover5State("reverse");
      setDiscover6State("reverse");
      setDiscover7State("reverse");
      setDiscover8State("reverse");
      setDiscover9State("reverse");
      setDiscover10State("reverse");
      setDiscover11State("reverse");
    }
  }, [discoverState]);
  return (
    <>
      <OurCommitmentsPallino
        pallinoSateProp={discover1State}
        iconaTop={<IconaDiscover />}
        nome={"discover1"}
        classe={"pallino-discover"}
        titolo={"THE SCRAP YARD"}
        testo={
          <Fragment>
            <p>Steel production begins with meticulous scrap selection</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover2State}
        iconaTop={<IconaDiscover />}
        nome={"discover2"}
        classe={"pallino-discover"}
        titolo={"THE FURNACE"}
        testo={
          <Fragment>
            <p>
              The electric furnace melting process is the cornerstone of our
              sustainable commitment
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover3State}
        iconaTop={<IconaDiscover />}
        nome={"discover3"}
        classe={"pallino-discover"}
        titolo={"THE ROLLING MILL (CONSTRUCTION STEEL)"}
        testo={
          <Fragment>
            <p>
              In the rolling mills of Lonato del Garda and Riesa, we produce
              steel for construction and infrastructural projects
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover4State}
        iconaTop={<IconaDiscover />}
        nome={"discover4"}
        classe={"pallino-discover"}
        titolo={"THE ROLLING MILL (SPECIALTIES)"}
        testo={
          <Fragment>
            <p>In Lecco, we roll high-value special steels</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover5State}
        iconaTop={<IconaDiscover />}
        nome={"discover5"}
        classe={"pallino-discover"}
        titolo={"PRODUCTIONS"}
        testo={
          <Fragment>
            <p>
              Not only rebar and wire rod: welded mesh and stretched coils are
              among the main manufactured products
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover6State}
        iconaTop={<IconaDiscover />}
        nome={"discover6"}
        classe={"pallino-discover"}
        titolo={"STEEL FOR MAJOR WORKS"}
        testo={
          <Fragment>
            <p>
              Not only production: we process pre-shaped, assembled components,
              and joints for major infrastructures
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover7State}
        iconaTop={<IconaDiscover />}
        nome={"discover7"}
        classe={"pallino-discover"}
        titolo={"CUSTOMISED STEEL"}
        testo={
          <Fragment>
            <p>
              Standard and customised products: broad diversification is one of
              our strengths
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover8State}
        iconaTop={<IconaDiscover />}
        nome={"discover8"}
        classe={"pallino-discover"}
        titolo={"INDUSTRIAL AND RESIDENTIAL STEEL"}
        testo={
          <Fragment>
            <p>
              In addition to steels for major construction sites or for special
              applications, we also offer solutions for other residential and
              industrial purposes
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover9State}
        iconaTop={<IconaDiscover />}
        nome={"discover9"}
        classe={"pallino-discover"}
        titolo={"AUTOMOTIVE STEEL"}
        testo={
          <Fragment>
            <p>
              Special steels, once drawn, find their application also in the
              automotive industry
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover10State}
        iconaTop={<IconaDiscover />}
        nome={"discover10"}
        classe={"pallino-discover"}
        titolo={"BUILDING SITE"}
        testo={
          <Fragment>
            <p>
              One of the highlights of our steel: providing supply and
              installation are among our services
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={discover11State}
        iconaTop={<IconaDiscover />}
        nome={"discover11"}
        classe={"pallino-discover"}
        titolo={"SCRAP, CIRCULAR CORE"}
        testo={
          <Fragment>
            <p>
              From an end to a new beginning: ferrous scrap will soon become new
              steel!
            </p>
          </Fragment>
        }
      />
    </>
  );
}

import { useEffect, useState, Fragment } from "react";

import OurCommitmentsPallino from "./OurCommitmentsPallino";

import { ReactComponent as IconaPannelloVerde } from "../../../assets/commitments/icona-pannello-verde.svg";
import { ReactComponent as IconaTreno } from "../../../assets/commitments/icona-treno.svg";
import { ReactComponent as Elettrification } from "../../../assets/commitments/elettrification.svg";
import { ReactComponent as Recycling } from "../../../assets/commitments/recycling.svg";
import { ReactComponent as Efficiency } from "../../../assets/commitments/efficiency.svg";

import "../../../css/pages/ourcommitments/our-commitments-fightingco2-pallini.scss";

export default function FightingCo2({ fightingCo2State }) {
  const [fightingCo21State, setFightingCo21State] = useState();
  const [fightingCo22State, setFightingCo22State] = useState();
  const [fightingCo23State, setFightingCo23State] = useState();
  const [fightingCo24State, setFightingCo24State] = useState();
  const [fightingCo25State, setFightingCo25State] = useState();

  useEffect(() => {
    if (fightingCo2State === 1) {
      setFightingCo21State("play");
    }
    if (fightingCo2State === 2) {
      setFightingCo22State("play");
    }
    if (fightingCo2State === 3) {
      setFightingCo23State("play");
    }
    if (fightingCo2State === 4) {
      setFightingCo24State("play");
    }
    if (fightingCo2State === 5) {
      setFightingCo25State("play");
    }
    if (fightingCo2State === "initial") {
      setFightingCo21State("reverse");
      setFightingCo22State("reverse");
      setFightingCo23State("reverse");
      setFightingCo24State("reverse");
      setFightingCo25State("reverse");
    }
  }, [fightingCo2State]);

  return (
    <>
      <OurCommitmentsPallino
        pallinoSateProp={fightingCo21State}
        iconaTop={<IconaPannelloVerde />}
        nome={"fightingCo21"}
        classe={"pallino-fightingco2"}
        titolo={"RENEWABLE ENERGIES"}
        testo={
          <Fragment>
            <p>
              Green energy's role will become increasingly important in the
              future: we plan to invest more than 200 million euros in the
              upcoming years
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={fightingCo22State}
        iconaTop={<Recycling />}
        nome={"fightingCo22"}
        classe={"pallino-fightingco2"}
        titolo={"RECYCLING WASTE"}
        testo={
          <Fragment>
            <p>Over 88% of waste recovered: our commitment does not stop!</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={fightingCo23State}
        iconaTop={<Elettrification />}
        nome={"fightingCo23"}
        classe={"pallino-fightingco2"}
        titolo={"ELECTRIFICATION"}
        testo={
          <Fragment>
            <p>
              Moving away from fossil fuels (natural gas), we are transitioning
              towards the electrification of our processes
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={fightingCo24State}
        iconaTop={<Efficiency />}
        nome={"fightingCo24"}
        classe={"pallino-fightingco2"}
        titolo={"EFFICIENCY"}
        testo={
          <Fragment>
            <p>
              Higher productivity with reduced consumption and impact: this is
              among the enhancement goals for our facilities
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={fightingCo25State}
        iconaTop={<IconaTreno />}
        nome={"fightingCo25"}
        classe={"pallino-fightingco2"}
        titolo={"SUSTAINABLE LOGISTIC"}
        testo={
          <Fragment>
            <p>
              Increasing rail transportation: our commitment includes a shift
              towards increasingly sustainable logistics
            </p>
          </Fragment>
        }
      />
    </>
  );
}

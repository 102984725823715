import PopUp from "../../../components/gsap/PopUp";

import prodEdilizia from "../../../../assets/commitments/prod-edilizia.png";

export default function OurCommitmentsFonderia2({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="prodEdilizia" state={state} delay={delay}>
        <img src={prodEdilizia} alt="prodEdilizia" />
      </PopUp>
    </>
  );
}

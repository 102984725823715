import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { MotionPathPlugin } from "gsap/all";

import { vh } from "./globals";

export default function ourCommitmentsDiscoverTimeline(
  ctx,
  ourCommitments,
  fonderia2StateHandler,
  magazzinoStateHandler,
  fonderia3StateHandler,
  rifiutiTrenoStateHandler,
  treniStateHandler,
  fabbrica1StateHandler,
  capannoneStateHandler,
  fabbrica2StateHandler,
  cantiereStateHandler,
  discoverPalliniStateHandler,
  scrollCompleteHandler,
  progressHandler
) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);

  fonderia2StateHandler("reverse");
  magazzinoStateHandler("reverse");
  fonderia3StateHandler("reverse");
  fabbrica1StateHandler("reverse");
  capannoneStateHandler("reverse");
  fabbrica2StateHandler("reverse");
  cantiereStateHandler("reverse");
  rifiutiTrenoStateHandler("reverse");
  treniStateHandler("reverse");
  scrollCompleteHandler(false);

  ctx.add(() => {
    gsap
      .timeline()
      .set(ourCommitments, { position: "relative" })
      .to("#mappa-our-commitments-cont", {
        scale: 1.8,
        x: 90 * vh,
        y: 5 * vh,
        force3D: true,
        duration: 1.5,
        ease: "power3.inOut",
      })
      .add(() => {
        discoverPalliniStateHandler(1);
      }, "-=0.2")
      .add(() => {
        discoverPalliniStateHandler(2);
      }, "+=0.1")
      .set(ourCommitments, { height: 1000 * vh })
      .add(() => {
        const elementiPalliniTimeline = gsap
          .timeline()
          .add(() => {
            fonderia2StateHandler("play");
            discoverPalliniStateHandler(3);
          }, 0.035)
          .add(() => {
            fonderia3StateHandler("play");
            discoverPalliniStateHandler(4);
          }, 0.13)
          .add(() => {
            magazzinoStateHandler("play");
            discoverPalliniStateHandler(5);
          }, 0.2)
          .add(() => {
            fabbrica1StateHandler("play");
            discoverPalliniStateHandler(6);
          }, 0.28)
          .add(() => {
            capannoneStateHandler("play");
            discoverPalliniStateHandler(7);
          }, 0.39)
          .add(() => {
            fabbrica2StateHandler("play");
            discoverPalliniStateHandler(8);
          }, 0.5)
          .add(() => {
            discoverPalliniStateHandler(9);
          }, 0.58)
          .add(() => {
            cantiereStateHandler("play");
            discoverPalliniStateHandler(10);
          }, 0.68)
          .add(() => {
            rifiutiTrenoStateHandler("play");
            discoverPalliniStateHandler(11);
            scrollCompleteHandler(true);
          }, 0.88);

        gsap
          .timeline({
            defaults: { duration: 1 },
            scrollTrigger: {
              trigger: ourCommitments,
              start: 0,
              scrub: "1",
              onUpdate: (elementiPalliniTimeline) => {
                progressHandler(elementiPalliniTimeline.progress * 114 + "%");
              },
            },
          })
          .to("#mappa-our-commitments-cont", {
            motionPath: {
              path: [
                { x: "90vh", y: "5vh" }, //fonderia1
                { x: "65vh", y: "-2vh" }, //fonderia2
                { x: "55vh", y: "30vh" }, //fonderia3
                { x: "25vh", y: "30vh" }, //magazzino
                { x: "25vh", y: "30vh" }, //fabbrica1
                { x: "25vh", y: "30vh" }, //fabbrica2
                { x: "0vh", y: "30vh" }, //fabbrica3
                { x: "-15vh", y: "30vh" }, //capannone
                { x: "-25vh", y: "10vh" }, //cantiere
                { x: "-30vh", y: "-15vh" }, //rifiutitreno
              ],
              curviness: 1,
            },
            ease: "none",
          })
          .add(elementiPalliniTimeline, 0);
      });
  });
}

import { useRef, useLayoutEffect, useEffect, useContext } from "react";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";

import { ScrollTriggerHandlerContext } from "../../helpers/contexts";

import "../../../css/components/gsap/text-with-background.scss";

const TextWithBackground = ({ children, state, delay }) => {
  const el = useRef();
  const tl = useRef();
  const ctx = useRef();

  const stateScrolltrigger = useContext(ScrollTriggerHandlerContext);
  if (!state) {
    state = stateScrolltrigger;
  }

  useLayoutEffect(() => {
    gsap.registerPlugin(SplitText);
    SplitText.create(el.current.getElementsByClassName("animating-text"), {
      type: "chars, words",
      charsClass: "twb-char",
    });
    ctx.current = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .paused(true)
        .to(
          el.current.getElementsByClassName("twb-after"),
          {
            autoAlpha: 1,
            x: 0,
            y: -1,
            skewX: 0,
            ease: "power3.inOut",
            duration: 1,
          },
          delay
        )
        .to(
          el.current.getElementsByClassName("twb-before"),
          {
            autoAlpha: 1,
            x: 0,
            y: 0,
            skewX: 0,
            ease: "power3.inOut",
            duration: 1,
          },
          "<"
        )
        .to(
          el.current.getElementsByClassName("twb-char"),
          {
            autoAlpha: 1,
            rotationX: 0,
            x: 0,
            y: 0,
            z: 0,
            stagger: 0.04,
            force3D: true,
            ease: "power1.inOut",
            duration: 0.9,
          },
          "<+=0.3"
        );
    }, el);
    return () => ctx.current.revert();
  }, [delay]);

  useEffect(() => {
    if (state === "play") {
      tl.current.play();
    }
    if (state === "pause") {
      tl.current.pause(0);
    }
  }, [state]);

  return (
    <div ref={el} className="text-with-background">
      <div className="twb-before"></div>
      <div className="twb-after"></div>
      {children}
    </div>
  );
};

export default TextWithBackground;

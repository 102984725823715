import { useEffect } from "react";

import Rotate from "./assets/rotate_device.png";
import OurCommitments from "./js/pages/ourcommitments/OurCommitments";

import "./css/base/reset.scss";
import "./css/base/mixins.scss";
import "./css/base/variables.scss";
import "./css/base/typography.scss";

function App() {
  const isMobilePortrait = window.matchMedia("(max-width: 768px) and (orientation: portrait)").matches;

  useEffect(() => {
    // Reload page on orientation change
    window.screen.orientation.addEventListener("change", function () {
      window.location.reload();
    });
  });

  return (
    <>
      {isMobilePortrait ? (
        <picture className="rotate">
          <img
            src={Rotate}
            alt="rotate device"
          ></img>
        </picture>
      ) : (
        <>
          <OurCommitments />
        </>
      )}
    </>
  );
}

export default App;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { MotionPathPlugin } from "gsap/all";

import { vh } from "./globals";

export default function ourCommitmentsInvestmentsTimeline(
  ctx,
  ourCommitments,
  rottamiStateHandler,
  pannelliFonderiaDestraStateHandler,
  fonderia1StateHandler,
  fonderia2StateHandler,
  fonderia3StateHandler,
  pannelliFabbricaStateHandler,
  investmentsPalliniStateHandler,
  scrollCompleteHandler,
  progressHandler
) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);

  rottamiStateHandler("reverse");
  fonderia1StateHandler("reverse");
  fonderia2StateHandler("reverse");
  fonderia3StateHandler("reverse");
  scrollCompleteHandler(false);

  ctx.add(() => {
    gsap
      .timeline()
      .set(ourCommitments, { position: "relative" })
      .to("#mappa-our-commitments-cont", {
        scale: 1.8,
        x: 110 * vh,
        y: 20 * vh,
        force3D: true,
        duration: 1.5,
        ease: "power3.inOut",
      })
      .add(() => {
        rottamiStateHandler("play");
        investmentsPalliniStateHandler(1);
      }, "-=0.2")
      .set(ourCommitments, { height: 700 * vh })
      .add(() => {
        const elementiPalliniTimeline = gsap
          .timeline()
          .add(() => {
            fonderia1StateHandler("play");
            investmentsPalliniStateHandler(2);
            gsap.delayedCall(0.3, () => {
              investmentsPalliniStateHandler(3);
            });
          }, 0.035)
          .add(() => {
            pannelliFonderiaDestraStateHandler("play");
            investmentsPalliniStateHandler(4);
          }, 0.22)
          .add(() => {
            fonderia2StateHandler("play");
            investmentsPalliniStateHandler(5);
            gsap.delayedCall(0.3, () => {
              investmentsPalliniStateHandler(6);
            });
            gsap.delayedCall(0.6, () => {
              investmentsPalliniStateHandler(7);
            });
          }, 0.4)
          .add(() => {
            fonderia3StateHandler("play");
            investmentsPalliniStateHandler(8);
          }, 0.6)
          .add(() => {
            pannelliFabbricaStateHandler("play");
            scrollCompleteHandler(true);
          }, 0.8);

        gsap
          .timeline({
            defaults: { duration: 1 },
            scrollTrigger: {
              trigger: ourCommitments,
              start: 0,
              scrub: "1",
              onUpdate: (elementiPalliniTimeline) => {
                progressHandler(elementiPalliniTimeline.progress * 125 + "%");
              },
            },
          })
          .to("#mappa-our-commitments-cont", {
            motionPath: {
              path: [
                { x: "110vh", y: "20vh" }, //inizio
                { x: "90vh", y: "20vh" }, //fonderia2
                { x: "65vh", y: "20vh" }, //fonderia3
                { x: "50vh", y: "10vh" }, //fabbrica1
                { x: "30vh", y: "20vh" }, //fabbrica3
              ],
              curviness: 1,
            },
            ease: "none",
          })
          .add(elementiPalliniTimeline, 0);
      });
  });
}

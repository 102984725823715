import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import { Helmet } from "react-helmet-async";

import "../../css/layout/main.scss";

export default function Main({ children, titolo, classe }) {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    window.onbeforeunload = function (event) {
      ScrollTrigger.clearScrollMemory();
      window.history.scrollRestoration = "manual";
    };
  });

  // Disable right click

  /*   useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []); */

  return (
    <>
      <Helmet>
        <title>{titolo}</title>
      </Helmet>

      <main className={classe}>
        {children}
        <div className="panel panel-in"></div>
        <div className="panel panel-out"></div>
      </main>
    </>
  );
}

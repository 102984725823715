import { useEffect, useRef } from "react";

import { gsap } from "gsap";

import { onerem } from "../../helpers/globals";

import "../../../css/pages/ourcommitments/our-commitments-progress-bar.scss";

export default function OurCommitmentsProgressBar({
  mapStateProp,
  progrssProp,
  progressHandler,
}) {
  const progressBar = useRef();
  const progressHandlerRef = useRef();
  progressHandlerRef.current = progressHandler;

  useEffect(() => {
    if (mapStateProp && mapStateProp !== "initial") {
      gsap.to(progressBar.current, { y: 0 });
    } else if (mapStateProp === "initial") {
      gsap.to(progressBar.current, { y: -1.5 * onerem });
      progressHandlerRef.current(0);
    }
  }, [mapStateProp]);

  useEffect(() => {
    gsap.to("#our-commitments-progress-bar-progress", {
      width: progrssProp,
    });
  }, [progrssProp]);

  return (
    <div id="our-commitments-progress-bar" ref={progressBar}>
      <div id="our-commitments-progress-bar-progress"></div>
    </div>
  );
}

import { useRef, useLayoutEffect, useEffect, useContext } from "react";
import { gsap } from "gsap";

import { ScrollTriggerHandlerContext } from "../../helpers/contexts";

import "../../../css/components/gsap/pop-up.scss";

const PopUp = ({ children, id, state, delay }) => {
  const stateScrolltrigger = useContext(ScrollTriggerHandlerContext);

  if (!state) {
    state = stateScrolltrigger;
  }
  const el = useRef();
  const tl = useRef();
  const ctx = useRef();

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap.timeline().paused(true).to(
        el.current,
        {
          scale: "1",
          duration: 0.8,
          force3D: true,
          ease: "back.out(1.6)",
        },
        delay
      );
    }, el);
    return () => ctx.current.revert();
  }, [delay]);

  useEffect(() => {
    if (state === "play") {
      tl.current.play();
    }
    if (state === "pause") {
      tl.current.pause();
    }
    if (state === "reverse") {
      tl.current.reverse(0);
    }
    if (state === "restart") {
      tl.current.restart();
    }
  }, [state]);

  return (
    <div id={id} ref={el} className="pop-up">
      {children}
    </div>
  );
};

export default PopUp;

import PopUp from "../../../components/gsap/PopUp";

import containerRifiuti from "../../../../assets/commitments/container-rifiuti.png";
import rottami1 from "../../../../assets/commitments/rottami-1.png";
import rottami2 from "../../../../assets/commitments/rottami-2.png";
import escavatrice from "../../../../assets/commitments/escavatrice.png";
import pilastri from "../../../../assets/commitments/pilastri.png";

export default function OurCommitmentsRottami({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="escavatrice1" state={state} delay={delay}>
        <img src={escavatrice} alt="rottami" />
      </PopUp>
      <PopUp id="rottami1" state={state} delay={delay + 0.05}>
        <img src={rottami1} alt="rottami" />
      </PopUp>
      <PopUp id="rottami2" state={state} delay={delay + 0.1}>
        <img src={rottami2} alt="rottami" />
      </PopUp>
      <PopUp id="rottami3" state={state} delay={delay + 0.15}>
        <img src={rottami1} alt="rottami" />
      </PopUp>
      <PopUp id="rottami4" state={state} delay={delay + 0.2}>
        <img src={rottami2} alt="rottami" />
      </PopUp>
      <PopUp id="escavatrice2" state={state} delay={delay + 0.25}>
        <img src={escavatrice} alt="rottami" />
      </PopUp>
      <PopUp id="containerRifiuti1" state={state} delay={delay + 0.35}>
        <img src={containerRifiuti} alt="containerRifiuti" />
      </PopUp>
      <PopUp id="containerRifiuti2" state={state} delay={delay + 0.4}>
        <img src={containerRifiuti} alt="containerRifiuti" />
      </PopUp>
      <PopUp id="pilastri" state={state} delay={delay + 0.7}>
        <img src={pilastri} alt="pilastri" />
      </PopUp>
    </>
  );
}

import { useEffect, useState, Fragment } from "react";

import OurCommitmentsPallino from "./OurCommitmentsPallino";

import { ReactComponent as IconaDollaro } from "../../../assets/commitments/icona-dollaro.svg";

import { ReactComponent as EfficiencyGiallo } from "../../../assets/commitments/efficiency-giallo.svg";
import { ReactComponent as Co2Giallo } from "../../../assets/commitments/icona-co2-giallo.svg";
import { ReactComponent as Continuity } from "../../../assets/commitments/continuity.svg";
import { ReactComponent as Qualita } from "../../../assets/commitments/qualita.svg";

import "../../../css/pages/ourcommitments/our-commitments-investments-pallini.scss";

export default function OurCommitmentsInvestmentsPallini({ investmentsState }) {
  const [investments1State, setInvestments1State] = useState();
  const [investments2State, setInvestments2State] = useState();
  const [investments3State, setInvestments3State] = useState();
  const [investments4State, setInvestments4State] = useState();
  const [investments5State, setInvestments5State] = useState();
  const [investments6State, setInvestments6State] = useState();
  const [investments7State, setInvestments7State] = useState();
  const [investments8State, setInvestments8State] = useState();

  useEffect(() => {
    if (investmentsState === 1) {
      setInvestments1State("play");
    }
    if (investmentsState === 2) {
      setInvestments2State("play");
    }
    if (investmentsState === 3) {
      setInvestments3State("play");
    }
    if (investmentsState === 4) {
      setInvestments4State("play");
    }
    if (investmentsState === 5) {
      setInvestments5State("play");
    }
    if (investmentsState === 6) {
      setInvestments6State("play");
    }
    if (investmentsState === 7) {
      setInvestments7State("play");
    }
    if (investmentsState === 8) {
      setInvestments8State("play");
    }
    if (investmentsState === "initial") {
      setInvestments1State("reverse");
      setInvestments2State("reverse");
      setInvestments3State("reverse");
      setInvestments4State("reverse");
      setInvestments5State("reverse");
      setInvestments6State("reverse");
      setInvestments7State("reverse");
      setInvestments8State("reverse");
    }
  }, [investmentsState]);
  return (
    <>
      <OurCommitmentsPallino
        pallinoSateProp={investments1State}
        iconaTop={<IconaDollaro />}
        nome={"investments1"}
        classe={"pallino-investments"}
        titolo={"NEW SCRAP REFINEMENT"}
        testo={
          <Fragment>
            <h3>
              <span>FERALPI STAHL - RIESA (GERMANY)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Co2Giallo />
              </div>
              <h3>-CO2 EMISSION</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Qualita />
              </div>
              <h3>+quality</h3>
            </div>
            <p>
              Not only for the protection of ferrous scrap, but also for optimized scrap preparation to further automize
              our production processes
            </p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments2State}
        iconaTop={<IconaDollaro />}
        nome={"investments2"}
        classe={"pallino-investments"}
        titolo={"FLYING TUNDISH CAR"}
        testo={
          <Fragment>
            <h3>
              Feralpi Siderurgica<span>LONATO DEL GARDA (Italy)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Continuity />
              </div>
              <h3>CONTINUITY</h3>
            </div>
            <p>A new plant that increases productivity in the continuous casting process</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments3State}
        iconaTop={<IconaDollaro />}
        nome={"investments3"}
        classe={"pallino-investments"}
        titolo={"RETRACTABLES OSCILLATORS"}
        testo={
          <Fragment>
            <h3>
              Feralpi Siderurgica<span>LONATO DEL GARDA (Italy)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Continuity />
              </div>
              <h3>CONTINUITY</h3>
            </div>
            <p>Facilitating maintenance without downtime: a new, more efficient and continuous plant</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments4State}
        iconaTop={<IconaDollaro />}
        nome={"investments4"}
        classe={"pallino-investments"}
        titolo={"NEW PHOTOVOLTAIC PARKS"}
        testo={
          <Fragment>
            <h3>
              <span>ITALY</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Co2Giallo />
              </div>
              <h3>-CO2 EMISSION</h3>
            </div>
            <p>Feralpi will invest over 200 million euros (2023-27) in renewable energy production</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments5State}
        iconaTop={<IconaDollaro />}
        nome={"investments5"}
        classe={"pallino-investments"}
        titolo={"BILLET INDUCTION HEATING"}
        testo={
          <Fragment>
            <h3>
              Feralpi Siderurgica<span>LONATO DEL GARDA (Italy)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Continuity />
              </div>
              <h3>CONTINUITY</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Co2Giallo />
              </div>
              <h3>-CO2 EMISSION</h3>
            </div>
            <p>Elimination of fossil fuels and electrification of processes: induction furnaces to heat the billets</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments6State}
        iconaTop={<IconaDollaro />}
        nome={"investments6"}
        classe={"pallino-investments"}
        titolo={"ROLLING MILL"}
        testo={
          <Fragment>
            <h3>
              Feralpi Stahl<span>RIESA (Germany)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Continuity />
              </div>
              <h3>CONTINUITY</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Co2Giallo />
              </div>
              <h3>-CO2 EMISSION</h3>
            </div>
            <p>Technology and innovation for the new rolling mill (2024)</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=HtA9-XgdcwY"
            >
              <p>learn more about the project</p>
            </a>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments7State}
        iconaTop={<IconaDollaro />}
        nome={"investments7"}
        classe={"pallino-investments"}
        titolo={"SPOOLER"}
        testo={
          <Fragment>
            <h3>
              FERALPI GROUP
              <span>RIESA (Germany) and LONATO DEL GARDA (Italy)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Continuity />
              </div>
              <h3>CONTINUITY</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Co2Giallo />
              </div>
              <h3>-CO2 EMISSION</h3>
            </div>
            <p>A new plant to be closer to market demands</p>
          </Fragment>
        }
      />
      <OurCommitmentsPallino
        pallinoSateProp={investments8State}
        iconaTop={<IconaDollaro />}
        nome={"investments8"}
        classe={"pallino-investments"}
        titolo={"POUR REEL LINE"}
        testo={
          <Fragment>
            <h3>
              Caleotto<span>LECCO (Italy)</span>
            </h3>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <EfficiencyGiallo />
              </div>
              <h3>+efficiency</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Continuity />
              </div>
              <h3>CONTINUITY</h3>
            </div>
            <div className="tooltip-icona-testo">
              <div className="tooltip-icona-testo-icona">
                <Co2Giallo />
              </div>
              <h3>-CO2 EMISSION</h3>
            </div>
            <p>A new plant to increase the production range of special steels</p>
          </Fragment>
        }
      />
    </>
  );
}

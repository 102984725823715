import PopUp from "../../../components/gsap/PopUp";

import rifiutiProd from "../../../../assets/commitments/rifiuti-prod.png";
import fusione from "../../../../assets/commitments/fusione.png";

export default function OurCommitmentsFonderia1({ state, delay = 0 }) {
  return (
    <>
      <PopUp id="rifiutiProd" state={state} delay={delay}>
        <img src={rifiutiProd} alt="rifiutiProd" />
      </PopUp>
      <PopUp id="fusione" state={state} delay={delay + 0.4}>
        <img src={fusione} alt="fusione" />
      </PopUp>
    </>
  );
}

import { useEffect, useState, useRef, useLayoutEffect } from "react";

import FadeInUp from "../../components/gsap/FadeInUp";
import FadeInLeft from "../../components/gsap/FadeInLeft";

import { gsap } from "gsap";

import { rem } from "../../helpers/globals";

import { ReactComponent as ScrollIcon } from "../../../assets/scroll-icon.svg";
import { ReactComponent as BackIcon } from "../../../assets/products/freccia-product.svg";
import { ReactComponent as SwitchCont } from "../../../assets/switch-cont.svg";
import { ReactComponent as SwitchPallino } from "../../../assets/switch-pallino.svg";

import "../../../css/pages/ourcommitments/our-commitments-switches.scss";

export default function OurCommitmentsSwitches({ mapStateProp, mapStateHandlerProp, scrollCompleteProp }) {
  const [scrollTipState, setScrollTipState] = useState();
  const [activeSwitch, setActiveSwitch] = useState();

  const animating = useRef(true);
  const ctx = useRef();
  const ctxScrollTip = useRef();
  const ourCommitmentsSwitchesCont = useRef();
  const switchesTimeline = useRef(gsap.timeline());
  const switchesTimelineInvert = useRef(gsap.timeline());
  const scrollTipCont = useRef();
  const scrollTipTimeline = useRef(gsap.timeline());

  useLayoutEffect(() => {
    var inactivityTime = function () {
      var time;
      window.onload = resetTimer;
      // DOM Events
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;

      function setToReverse() {
        mapStateHandlerProp("initial");
        gsap.delayedCall(1, () => {
          setActiveSwitch(undefined);
        });
      }

      function resetTimer() {
        clearTimeout(time);
        time = setTimeout(setToReverse, 180000);
        // 180000 milliseconds = 3 minutes
      }
    };

    inactivityTime();
    setTimeout(() => {
      animating.current = false;
    }, 3400);
  }, [mapStateHandlerProp]);

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      if (activeSwitch) {
        let switchActive = activeSwitch.closest(".our-commitments-switch-cont-cont");

        const previousSiblings = (elem) => {
          let prevSiblings = [];
          while (elem.previousElementSibling) {
            prevSiblings.push(elem.previousElementSibling);
            elem = elem.previousElementSibling;
          }
          return prevSiblings;
        };

        const nextSiblings = (elem) => {
          let nextSiblings = [];
          while (elem.nextElementSibling) {
            nextSiblings.push(elem.nextElementSibling);
            elem = elem.nextElementSibling;
          }
          return nextSiblings;
        };
        const switchSx = gsap.utils.toArray(previousSiblings(switchActive));
        const switchDx = gsap.utils.toArray(nextSiblings(switchActive));

        let switchWidth = switchActive.offsetWidth;
        let style = window.getComputedStyle(switchActive);
        let marginRight = parseFloat(style.marginRight);

        switchesTimeline.current = gsap
          .timeline({
            paused: true,
            defaults: { duration: 1, ease: "power3.inOut" },
          })
          .to(switchActive, { marginRight: 0.5 * rem });

        if (switchSx.length) {
          switchesTimeline.current.to(
            switchSx,
            {
              width: 0.6 * rem,
              background: "#e6e6e6",
              marginRight: 0.5 * rem,
            },
            "<"
          );
        }
        if (switchDx.length) {
          switchesTimeline.current.to(
            switchDx,
            {
              width: 0.6 * rem,
              background: "#e6e6e6",
              marginRight: 0.5 * rem,
            },
            "<"
          );
        }
        switchesTimeline.current.to(
          ourCommitmentsSwitchesCont.current,
          {
            width: switchWidth + (0.8 + 0.5 * 5 + 0.6 * 5 + 0.8) * rem,
          },
          "<+0.1"
        );

        switchesTimelineInvert.current = gsap
          .timeline({
            paused: true,
            defaults: { duration: 1, ease: "power3.inOut" },
          })
          .to(ourCommitmentsSwitchesCont.current, {
            width: "calc(100% - (100vw - 86.1rem))",
            ease: "power2.inOut",
            duration: 0.8,
          })
          .to(switchActive, { marginRight: marginRight }, "<");
        if (switchSx.length) {
          switchesTimelineInvert.current.to(
            switchSx,
            {
              width: switchWidth,
              background: "none",
              marginRight: marginRight,
            },
            "<"
          );
        }
        if (switchDx.length) {
          switchesTimelineInvert.current.to(
            switchDx,
            {
              width: switchWidth,
              background: "none",
              marginRight: marginRight,
            },
            "<"
          );
        }
      }
    }, ourCommitmentsSwitchesCont);
    return () => ctx.current.revert();
  }, [activeSwitch]);

  useLayoutEffect(() => {
    ctxScrollTip.current = gsap.context(() => {
      scrollTipTimeline.current = gsap
        .timeline({
          paused: true,
          defaults: { duration: 0.6, ease: "power3.inout" },
        })
        .to(
          ".link-freccia-after, .scroll-tip-testo, .link-freccia-before",
          {
            height: 4 * rem,
          },
          "<"
        )
        .to(
          ".link-freccia-after path",
          {
            attr: { d: "M445.45677,0h0L0,419.94,445.45677,841.895h0Z" },
            x: 1,
          },
          "<"
        )
        .to(
          ".link-freccia-before path",
          {
            attr: { d: "M0,841.89H425.971L21,420.94434,425.971,0,0,0Z" },
          },
          "<"
        )
        .to(
          ".scroll-tip-testo",
          {
            width: 17.9 * rem,
          },
          "<"
        )
        .to(
          ".scroll-tip-testo-cont",
          {
            y: -4.7 * rem,
          },
          "<"
        );
    }, scrollTipCont);
    return () => ctx.current.revert();
  }, []);

  useEffect(() => {
    if (mapStateProp && mapStateProp !== "initial") {
      setScrollTipState("play");
      switchesTimeline.current.play();
    } else {
      setScrollTipState("reverse");
      switchesTimelineInvert.current.play();
    }
  }, [mapStateProp]);

  useEffect(() => {
    if (scrollCompleteProp) {
      scrollTipTimeline.current.play();
    } else {
      gsap.delayedCall(1, () => {
        scrollTipTimeline.current.reverse();
      });
    }
  }, [scrollCompleteProp]);

  return (
    <>
      <div
        className="scroll-tip-cont"
        ref={scrollTipCont}
      >
        <FadeInLeft
          state={scrollTipState}
          delay="0.8"
        >
          <div id="scroll-tip">
            <div id="nome-sezione-freccia">
              <svg
                className="link-freccia-after"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 445 842"
              >
                <path d="M 445 0 H 0 l 420 420 L 0 842 H 445 Z" />
              </svg>
              <div className="scroll-tip-testo">
                <div
                  id="scroll-tip-testo-primo"
                  className="scroll-tip-testo-cont"
                >
                  <ScrollIcon />
                  <p id="titolo-freccia">scroll to explore</p>
                </div>
                <div
                  id="scroll-tip-testo-secondo"
                  className="scroll-tip-testo-cont"
                  onClick={() => {
                    mapStateHandlerProp("initial");
                    gsap.delayedCall(1, () => {
                      setActiveSwitch(undefined);
                    });
                  }}
                >
                  <BackIcon />
                  <p id="titolo-freccia">back</p>
                </div>
              </div>
              <svg
                className="link-freccia-before"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 425.97103 841.89001"
              >
                <path d="M0,841.89,425.971,420.94434,0,0Z" />
              </svg>
            </div>
          </div>
        </FadeInLeft>
      </div>
      <div
        className="our-commitments-switches"
        ref={ourCommitmentsSwitchesCont}
      >
        <div
          className="our-commitments-switch-cont-cont"
          id="our-commitments-switch-discover"
        >
          <FadeInUp
            state="play"
            delay={"1.5"}
          >
            <div className="our-commitments-switch-cont">
              <h4>
                Discover
                <br></br>
                <span className="sottotitolo">our value chain</span>
              </h4>
              <div
                className={mapStateProp === "discover" ? "mappa-switch-switch active" : "mappa-switch-switch"}
                onClick={(e) => {
                  if (!animating.current) {
                    mapStateProp === "discover" ? mapStateHandlerProp("initial") : mapStateHandlerProp("discover");
                    activeSwitch
                      ? gsap.delayedCall(1, () => {
                          setActiveSwitch(undefined);
                        })
                      : setActiveSwitch(e.target.closest(".fade-in-up"));
                  }
                }}
              >
                <SwitchCont />
                <SwitchPallino />
              </div>
            </div>
          </FadeInUp>
        </div>

        <div
          className="our-commitments-switch-cont-cont"
          id="our-commitments-switch-fighting-co2"
        >
          <FadeInUp
            state="play"
            delay={"1.65"}
          >
            <div className="our-commitments-switch-cont">
              <h4>
                FIGHTING CO<span className="pedice">2</span>
                <br></br>
                <span className="sottotitolo">EMISSION</span>
              </h4>
              <div
                className={mapStateProp === "fighting-co2" ? "mappa-switch-switch active" : "mappa-switch-switch"}
                onClick={(e) => {
                  if (!animating.current) {
                    mapStateProp === "fighting-co2"
                      ? mapStateHandlerProp("initial")
                      : mapStateHandlerProp("fighting-co2");
                    activeSwitch
                      ? gsap.delayedCall(1, () => {
                          setActiveSwitch(undefined);
                        })
                      : setActiveSwitch(e.target.closest(".fade-in-up"));
                  }
                }}
              >
                <SwitchCont />
                <SwitchPallino />
              </div>
            </div>
          </FadeInUp>
        </div>

        <div
          className="our-commitments-switch-cont-cont"
          id="our-commitments-switch-in-out"
        >
          <FadeInUp
            state="play"
            delay={"1.8"}
          >
            <div className="our-commitments-switch-cont">
              <h4>
                IN&OUT
                <br></br>
                <span className="sottotitolo">
                  A CIRCULAR<br></br>PROCESS
                </span>
              </h4>
              <div
                className={mapStateProp === "in-out" ? "mappa-switch-switch active" : "mappa-switch-switch"}
                onClick={(e) => {
                  if (!animating.current) {
                    mapStateProp === "in-out" ? mapStateHandlerProp("initial") : mapStateHandlerProp("in-out");
                    activeSwitch
                      ? gsap.delayedCall(1, () => {
                          setActiveSwitch(undefined);
                        })
                      : setActiveSwitch(e.target.closest(".fade-in-up"));
                  }
                }}
              >
                <SwitchCont />
                <SwitchPallino />
              </div>
            </div>
          </FadeInUp>
        </div>

        <div
          className="our-commitments-switch-cont-cont"
          id="our-commitments-switch-investments"
        >
          <FadeInUp
            state="play"
            delay={"1.95"}
          >
            <div className="our-commitments-switch-cont">
              <h4>
                INVESTMENTS
                <br></br>
                <span className="sottotitolo">FOR THE FUTURE</span>
              </h4>
              <div
                className={mapStateProp === "investments" ? "mappa-switch-switch active" : "mappa-switch-switch"}
                onClick={(e) => {
                  if (!animating.current) {
                    mapStateProp === "investments"
                      ? mapStateHandlerProp("initial")
                      : mapStateHandlerProp("investments");
                    activeSwitch
                      ? gsap.delayedCall(1, () => {
                          setActiveSwitch(undefined);
                        })
                      : setActiveSwitch(e.target.closest(".fade-in-up"));
                  }
                }}
              >
                <SwitchCont />
                <SwitchPallino />
              </div>
            </div>
          </FadeInUp>
        </div>

        <div
          className="our-commitments-switch-cont-cont"
          id="our-commitments-switch-the-future"
        >
          <FadeInUp
            state="play"
            delay={"2.1"}
          >
            <div className="our-commitments-switch-cont">
              <h4>
                THE FUTURE
                <br></br>
                <span className="sottotitolo">OF ENERGY</span>
              </h4>
              <div
                className={mapStateProp === "the-future" ? "mappa-switch-switch active" : "mappa-switch-switch"}
                onClick={(e) => {
                  if (!animating.current) {
                    mapStateProp === "the-future" ? mapStateHandlerProp("initial") : mapStateHandlerProp("the-future");
                    activeSwitch
                      ? gsap.delayedCall(1, () => {
                          setActiveSwitch(undefined);
                        })
                      : setActiveSwitch(e.target.closest(".fade-in-up"));
                  }
                }}
              >
                <SwitchCont />
                <SwitchPallino />
              </div>
            </div>
          </FadeInUp>
        </div>
      </div>
    </>
  );
}

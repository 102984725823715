import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { MotionPathPlugin } from "gsap/all";

import { vh } from "./globals";

export default function ourCommitmentsInOutTimeline(
    ctx,
    ourCommitments,
    fonderia2StateHandler,
    fonderia3StateHandler,
    treniStateHandler,
    villaggioRosaStateHandler,
    inOutPalliniStateHandler,
    scrollCompleteHandler,
    progressHandler
) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(MotionPathPlugin);

    fonderia2StateHandler("reverse");
    fonderia3StateHandler("reverse");
    treniStateHandler("reverse");
    villaggioRosaStateHandler("reverse");
    scrollCompleteHandler(false);

    ctx.add(() => {
        gsap
            .timeline()
            .set(ourCommitments, { position: "relative" })
            .to("#mappa-our-commitments-cont", {
                scale: 1.8,
                x: 90 * vh,
                y: 20 * vh,
                force3D: true,
                duration: 1.5,
                ease: "power3.inOut",
            })
            .add(() => {
                inOutPalliniStateHandler(1);
            }, "-=0.2")
            .set(ourCommitments, { height: 300 * vh })
            .add(() => {
                const elementiPalliniTimeline = gsap
                    .timeline()
                    .add(() => {
                        fonderia2StateHandler("play");
                        inOutPalliniStateHandler(3);
                        gsap.delayedCall(0.3, () => {
                            fonderia3StateHandler("play");
                            inOutPalliniStateHandler(5);
                        });
                        gsap.delayedCall(0.6, () => {
                            inOutPalliniStateHandler(7);
                        });
                    }, 0.25)
                    .add(() => {
                        villaggioRosaStateHandler("play");
                        inOutPalliniStateHandler(4);
                        gsap.delayedCall(0.3, () => {
                            inOutPalliniStateHandler(6);
                        });
                        gsap.delayedCall(0.6, () => {
                            inOutPalliniStateHandler(8);
                        });
                        gsap.delayedCall(0.9, () => {
                            inOutPalliniStateHandler(2);
                        });
                        scrollCompleteHandler(true);
                    }, 0.6);

                gsap
                    .timeline({
                        defaults: { duration: 1 },
                        scrollTrigger: {
                            trigger: ourCommitments,
                            start: 0,
                            scrub: "1",
                            onUpdate: (elementiPalliniTimeline) => {
                                progressHandler(elementiPalliniTimeline.progress * 150 + "%");
                            },
                        },
                    })
                    .to("#mappa-our-commitments-cont", {
                        motionPath: {
                            path: [
                                { x: "90vh", y: "20vh" }, //fonderia2
                                { x: "80vh", y: "15vh" }, //fonderia3
                                { x: "70vh", y: "10vh" }, //villaggio
                            ],
                            curviness: 1,
                        },
                        ease: "none",
                    })
                    .add(elementiPalliniTimeline, 0);
            });
    });
}

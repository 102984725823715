import OurCommitmentsRottami from "./elementi/OurCommitmentsRottami";
import OurCommitmentsFonderia1 from "./elementi/OurCommitmentsFonderia1";
import OurCommitmentsFonderia2 from "./elementi/OurCommitmentsFonderia2";
import OurCommitmentsMagazzino from "./elementi/OurCommitmentsMagazzino";
import OurCommitmentsPannelliFonderia from "./elementi/OurCommitmentsPannelliFonderia";
import OurCommitmentsFonderia3 from "./elementi/OurCommitmentsFonderia3";
import OurCommitmentsRifiutiTreno from "./elementi/OurCommitmentsRifiutiTreno";
import OurCommitmentsTreni from "./elementi/OurCommitmentsTreni";
import OurCommitmentsFabbrica1 from "./elementi/OurCommitmentsFabbrica1";
import OurCommitmentsCapannone from "./elementi/OurCommitmentsCapannone";
import OurCommitmentsFabbrica2 from "./elementi/OurCommitmentsFabbrica2";
import OurCommitmentsCantiere from "./elementi/OurCommitmentsCantiere";

import OurCommitmentsPannelliFabbrica from "./elementi/OurCommitmentsPannelliFabbrica";
import OurCommitmentsVillaggioRosa from "./elementi/OurCommitmentsVillaggioRosa";
import OurCommitmentsPannelliFonderiaDestra from "./elementi/OurCommitmentsPannelliFonderiaDestra";
import OurCommitmentsParcoEolico from "./elementi/OurCommitmentsParcoEolico";
import OurCommitmentsIdrogeno from "./elementi/OurCommitmentsIdrogeno";
import OurCommitmentsFattoria from "./elementi/OurCommitmentsFattoria";

import casetta from "../../../assets/commitments/casetta.png";

import "../../../css/pages/ourcommitments/our-commitments-elements.scss";

export default function OurCommitmentsElements({
  coloreElementsProp,
  rottamiStateProp,
  fonderia1StateProp,
  fonderia2StateProp,
  magazzinoStateProp,
  pannelliFonderiaStateProp,
  fonderia3StateProp,
  rifiutiTrenoStateProp,
  treniStateProp,
  fabbrica1StateProp,
  capannoneStateProp,
  fabbrica2StateProp,
  cantiereStateProp,
  pannelliFabbricaStateProp,
  villaggioRosaStateProp,
  pannelliFonderiaDestraStateProp,
  parcoEolicoStateProp,
  idrogenoStateProp,
  fattoriaStateProp,
}) {
  return (
    <>
      <div id="casetta-sopra">
        <img src={casetta} alt={casetta} />
      </div>
      <OurCommitmentsRottami state={rottamiStateProp} />
      <OurCommitmentsFonderia1 state={fonderia1StateProp} />
      <OurCommitmentsFonderia2 state={fonderia2StateProp} />
      <OurCommitmentsMagazzino state={magazzinoStateProp} />
      <OurCommitmentsPannelliFonderia
        state={pannelliFonderiaStateProp}
        color={coloreElementsProp}
      />
      <OurCommitmentsFonderia3 state={fonderia3StateProp} />
      <OurCommitmentsRifiutiTreno state={rifiutiTrenoStateProp} />
      <OurCommitmentsTreni state={treniStateProp} />
      <OurCommitmentsFabbrica1 state={fabbrica1StateProp} />
      <OurCommitmentsCapannone state={capannoneStateProp} />
      <OurCommitmentsFabbrica2 state={fabbrica2StateProp} />
      <OurCommitmentsCantiere state={cantiereStateProp} />
      <OurCommitmentsPannelliFabbrica
        state={pannelliFabbricaStateProp}
        color={coloreElementsProp}
      />
      <OurCommitmentsVillaggioRosa state={villaggioRosaStateProp} />
      <OurCommitmentsPannelliFonderiaDestra
        state={pannelliFonderiaDestraStateProp}
        color={coloreElementsProp}
      />
      <OurCommitmentsIdrogeno state={idrogenoStateProp} />
      <OurCommitmentsParcoEolico state={parcoEolicoStateProp} />
      <OurCommitmentsFattoria state={fattoriaStateProp} />
    </>
  );
}

import { useRef, useLayoutEffect, useEffect, useContext } from "react";
import { gsap } from "gsap";

import { ScrollTriggerHandlerContext } from "../../helpers/contexts";

import "../../../css/components/gsap/fade-in-up.scss";

const FadeInUp = ({ children, state, delay = 0, duration = 1.5 }) => {
  const stateScrolltrigger = useContext(ScrollTriggerHandlerContext);

  if (!state) {
    state = stateScrolltrigger;
  }
  const el = useRef();
  const tl = useRef();
  const ctx = useRef();

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .paused(true)
        .set(el.current, {
          display: "block",
        })
        .to(
          el.current,
          {
            y: "0",
            autoAlpha: "1",
            duration: duration,
            ease: "power3.inOut",
          },
          delay
        );
    }, el);
    return () => ctx.current.revert();
  }, [delay, duration]);

  useEffect(() => {
    if (state === "play") {
      tl.current.play();
    }
    if (state === "pause") {
      tl.current.pause(0);
    }
    if (state === "reverse") {
      tl.current.reverse();
    }
  }, [state]);

  return (
    <div ref={el} className="fade-in-up">
      {children}
    </div>
  );
};

export default FadeInUp;

import PopUp from "../../../components/gsap/PopUp";

import basePannelli from "../../../../assets/commitments/base-pannelli.png";
import pannelloVerticaleSx from "../../../../assets/commitments/pannello-verticale-sx.png";
import pannelloVerticaleVerdeSx from "../../../../assets/commitments/pannello-verticale-verde-sx.png";

export default function OurCommitmentsPannelliFonderia({
  state,
  color,
  delay = 0,
}) {
  return (
    <>
      <PopUp id="basePannelliFonderia" state={state} delay={delay}>
        <img src={basePannelli} alt="basePannelli" />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia1"
        state={state}
        delay={delay + 0.5}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia2"
        state={state}
        delay={delay + 0.55}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia3"
        state={state}
        delay={delay + 0.6}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia4"
        state={state}
        delay={delay + 0.65}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia5"
        state={state}
        delay={delay + 0.7}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia6"
        state={state}
        delay={delay + 0.75}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia7"
        state={state}
        delay={delay + 0.8}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia8"
        state={state}
        delay={delay + 0.85}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
      <PopUp
        id="pannelloVerticaleSxFonderia9"
        state={state}
        delay={delay + 0.9}
      >
        <img
          src={
            color === "verde" ? pannelloVerticaleVerdeSx : pannelloVerticaleSx
          }
          alt="pannelloVerticaleSx"
        />
      </PopUp>
    </>
  );
}
